import React, { Component, useEffect, useRef,useCallback,useState} from 'react';
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { FaCirclePlay } from "react-icons/fa6";
import { FaCirclePause } from "react-icons/fa6";
import { GoMute,GoUnmute  } from "react-icons/go";

const Thumbnail = styled.img`
    position: absolute;
    bottom: 50px; 
    left: ${props => props.left}%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%;
`;

const Timeline = ({startTime,chats,setScrollToTime,clusterArray,videoRef,isStreamer}) => {

    const [play,setPlay] = useState(true)
    const [mute,setMute] = useState(true)

    // useEffect(() => {
    //     // console.log("chats",chats)
    //     setPlay(true)
    // },[videoRef.current.pla])

    const timeToPercentage = (time, totalVideoTime) => {
        return (time / totalVideoTime) * 100;
      };

      const handleThumbnailClick = (e,messageId) => {
        e.preventDefault();

        // const videoElement = videoRef.current;
        const dataPoint = chats.find(data => data.messageId === messageId);
        // if (dataPoint && isFinite(videoElement.duration)) {
        //     const newTime = (dataPoint.time / 100) * videoElement.duration;
        //     videoElement.currentTime = newTime;
        //     const progress = (newTime / videoElement.duration) * 100;
        //     setProgressValue(progress); // Update the progressValue state here
        // }
        if(dataPoint){
          console.log(dataPoint.seconds)
          // setProgressValue(dataPoint.seconds)
          setScrollToTime(dataPoint.messageId)
        }
      };

      const videoPlayPause = () => {
        const videoElement = videoRef.current;
        if (videoElement.paused) {
          videoElement.play();
          setPlay(true)
          // if (!isStreamer){
          //     document.getElementById("stream-status").style.display = "none";
          // }
          // document.getElementById("play-btn").innerHTML = <i><FaCirclePause /></i>
          //change the button to FaCirclePause
         

        } else {
          videoElement.pause();
          setPlay(false)
          // document.getElementById("play-btn").appendChild(<i><FaCirclePause /></i>)
        }
      };

      const videoMutefunc = () => {
        const videoElement = videoRef.current;
        if (videoElement.muted) {
          videoElement.muted = false;
          setMute(false)
          // document.getElementById("mute-btn").innerHTML = "mute";
        } else {
          videoElement.muted = true;
          setMute(true)
          // document.getElementById("mute-btn").innerHTML = "unmute";
        }
      };

    return (
    <div id="thumbnailsContainer" style={{zIndex:"200",cursor:"pointer"}}>
      {/* <button id="play-btn" onClick={videoPlayPause}>play</button> */}
      {!isStreamer &&
       <>
        {!play ? <FaCirclePlay id="play-btn" onClick={videoPlayPause} style={{color:"red",width:"40px",height:"40px", padding:"4px"}}/>:
        <FaCirclePause id="play-btn" onClick={videoPlayPause} style={{color:"red",width:"40px",height:"40px",padding:"4px"}}/>}
        {!mute ? <GoMute id="mute-btn" onClick={videoMutefunc} style={{color:"red",width:"40px",height:"40px",padding:"4px"}}/>:
        <GoUnmute id="mute-btn" onClick={videoMutefunc} style={{color:"red",width:"40px",height:"40px",padding:"4px"}}/>}
        </>
      }
              <div style={{backgroundColor:"lightgray",width:"100%",opacity:"0.8",height:"15px",position:"absolute"}}></div>
            {startTime.current!== null && chats.filter((chat) => chat.images.length>0).map((chat,index) => {
            // console.log(chat)
            // const imageindex = chat.images.length
            // console.log("secondchat",chat.seconds)
            // console.log("startime", startTime.current)
            // console.log(`${timeToPercentage(chat.seconds- startTime.current, Date.now() - startTime.current)}%`)
            const messageId = chat.messageId
            
            return (
                <span key={index}>
                  {/* thumbnail position */}
                  <div className="thumbnail"
                  id = {`thumbnailbox${index}`}
                      style={{backgroundColor:"red",width:"15px",opacity:"0.5",height:"15px",
                      zIndex:"100",
                    position:"absolute",left:`${timeToPercentage(chat.seconds- startTime.current, Date.now() - startTime.current)}%`}}
                      // left={timeToPercentage(chat.seconds- startTime.current, Date.now() - startTime.current)} 
                      onClick={(e) => handleThumbnailClick(e,messageId)}
                      onMouseEnter = {(e)=>{
                        e.preventDefault()
                        if (document.getElementById(`thumbnail${index}`) !== null){
                        document.getElementById(`thumbnail${index}`).style.display="block"
                        }
                      }}
                      onMouseLeave = {(e)=>{
                        e.preventDefault
                        if (document.getElementById(`thumbnail${index}`) !== null){
                        document.getElementById(`thumbnail${index}`).style.display="none"
                        }
                      }}
                      
                  />
                  <span id = {`thumbnail${index}`} 
                  style={{display:"none",left:`${timeToPercentage(chat.seconds- startTime.current, Date.now() - startTime.current)}%`,
                position:"absolute",zIndex:"200"}}
                  //  left={timeToPercentage(chat.seconds- startTime.current, Date.now() - startTime.current)} 
                  >
                  {chat.images.map((image,img_index) => {
                    // console.log(image)
                    // console.log(clusterArray)
                    // let cluster
                    // console.log(image.imageId)
                    if (clusterArray.current.length>= image.imageId){
                    //   clusterArray[image.imageId] 
                    const   cluster = clusterArray.current[image.imageId]
                    //   document.getElementById("")
                    // console.log(`cluster${image.imageId}`,cluster)  
                        if (document.getElementById(`thumbnailbox${index}`) !== null){
                                if (cluster === 0){
                                document.getElementById("thumbnailbox"+index).style.backgroundColor = "red"
                                }else if (cluster === 1){
                                    document.getElementById("thumbnailbox"+index).style.backgroundColor = "blue"
                                }else if (cluster === 2){
                                    document.getElementById("thumbnailbox"+index).style.backgroundColor = "green"
                                }
                        }
                    }
                    return(
                    <Thumbnail 
                        key={img_index}
                        // id = {`thumbnail${index}_${img_index}`}
                        src={image.image} 
                        // left={timeToPercentage(chat.seconds- startTime.current, Date.now() - startTime.current)} 
                        // onClick={() => handleThumbnailClick(chat.messageId)}
                    />
                  )
            })}
                  </span>
                  </span>
            )})}
          
        </div>
    );
    };
export default Timeline;