import e from 'cors';
import React, { useEffect, useState, useRef } from 'react';
import { messageSend } from '../routes/socket';

function MessageInput({ message, setMessage, chats, setChats, username, userId, isStreamer}) {
    const [content, setContent] = useState({ __html: '' });
    const contentEditableRef = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
        // console.log('content', content.__html);
    }, [content]);

    useEffect(() => {
        console.log('message', message);
      // if (message.texts.length > 0 && message.images.length > 0) {
        if (isFirstRender.current) {
          // console.log('isFirstRender', isFirstRender.current);
            setContent({ __html: buildContent(message) });
            isFirstRender.current = false;
            console.log('isFirstRender', isFirstRender.current);
            
        }else if (message.index === 0) {
          setContent({ __html: "" });
          contentEditableRef.current.innerHTML = "";
          // console.log('isFirstRender', isFirstRender.current) 
        }
      // }
    }, [message]);

    useEffect(() => {
        if (!isFirstRender.current) {
            // console.log('message.images.length', message.images.length)
            setContent({ __html: buildContent(message) });
        }
    }, [message.images.length]);

  //   useEffect(() => {
  //     if (!isFirstRender.current) {
  //         setContent({ __html: buildContent(message) });
  //     }
  // }, [message.texts.length]);

    const handleInput = () => {
      try {
        const divContent = contentEditableRef.current.innerHTML;
        const newContent = parseContent(divContent);
        // console.log('newContent', newContent);
        setMessage(newContent);
      } catch (err) {
        console.log(err);
      }
        
    };

      
    const buildContent = (message) => {
        let newContent = '';
        // console.log(message);
        // const maxLength = Math.max(message.texts.length, message.images.length);
        // console.log('message.index', message.index);
        const maxLength = message.index;
    
        for (let i = 0; i < maxLength; i++) {
          if (message.texts.findIndex((text)=>text.index === i)!==-1) {
            const index = message.texts.findIndex((text)=>text.index === i);
            newContent += `<span>${message.texts[index].content}</span>`;
          }
          if (message.images.findIndex((image)=>image.index === i)!==-1) {
            const index = message.images.findIndex((image)=>image.index === i);
            newContent += `<img id="thingmoji-icon" src="${message.images[index].image}" originalsrc="${message.images[index].originalImage}" alt="image-${index}" style="width: 34px; height: 34px;" />`;
          }
  
      }
        return newContent;
      };
  



    const parseContent = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      let index = 0;
      let newContent = { texts: [], images: [] };
      doc.body.childNodes.forEach((node) => {
          if (node.nodeName === '#text'|| node.nodeName === 'SPAN') {
          newContent.texts.push({content:node.textContent, index: index});
          } else if(node.nodeName === 'IMG') {
          newContent.images.push({ image: node.src, originalImage: node.getAttribute('originalsrc'), index: index });
        }
        index++;  
      });

      newContent.index = index;
      // console.log('newContent', newContent);
      return newContent;
    };

    const handleCursorChange = () => {

    };

    useEffect(() => {
        const div = contentEditableRef.current;
    
        if (div) {
          // Add an event listener to the contentEditable div to handle cursor changes
          div.addEventListener('click', handleCursorChange);
          div.addEventListener('keyup', handleCursorChange);
    
          return () => {
            // Remove the event listeners when the component is unmounted
            div.removeEventListener('click', handleCursorChange);
            div.removeEventListener('keyup', handleCursorChange);
          };
        }
      }, []);

      function getCurrentTime() {
        let now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        let second = now.getSeconds();
        return hour + ":" + minute;
      }

      function getCurrentSecond() {
        return Date.now();
      }

      const sendMessage = (e) => {
          e.preventDefault();
          const messageData = {
            type: "chatMessage",
            data:{
                texts: message.texts,
                username: username.current,
                userId: userId.current,
                hasImage: !!message.images,
                images: message.images,
                time: getCurrentTime(),
                seconds: getCurrentSecond(),
                index: message.index,
                isStreamer: isStreamer,
            }
            // originImage: message.originImage // Adding the originImage property
        };
       
        // console.log(messageData)
       
        messageSend(messageData);
      
        setMessage({ texts: [], images: [], index:0 });
        // setIsShowThingmoji(false);
          // Send the message
      }


  return (
    <div style={{ display: "inline-block",width:"68%",position:"absolute"}}
    >
      <div
        id = 'contentEditable'
        contentEditable
        suppressContentEditableWarning
        ref={contentEditableRef}
        onInput={handleInput}
        dangerouslySetInnerHTML={content}
        style={{
          border: '1px solid #9ad4ed',
          // padding: '5px',
          // borderRadius: '4px',
          width: '100%',
          marginLeft: '5px',
          minHeight:'40px',
          maxHeight:'100px',
          overflowY: 'auto',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          paddingTop: '2px',
          paddingBottom: '2px',
          background: `rgba(255, 255, 255, 0.5)`,
          // hover: `rgba(255, 255, 255, 0.5)`,
        }}
        //send message when press enter
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            //if the message is not empty
            // const textcontent = contentEditableRef.current.text
            // console.log('contentEditableRef.current.textContent', contentEditableRef.current.children);
            if (contentEditableRef.current.children.length > 0 || contentEditableRef.current.textContent.length > 0) {
              sendMessage(e);
            }
          }
        }}
        //put focus on the input box
        // autoFocus = {true}
        onFocus={(e) => {
            let value = contentEditableRef.current.innerHTML;
            // console.log(value, 'value');
            contentEditableRef.current.innerHTML = '';
            contentEditableRef.current.innerHTML = value;
        } }
      />
    </div>
  );
}

export default MessageInput;