// import * as React from "react";
import Chatbox from "../chat/Chatbox.js";
// import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
// import {getStream} from './chatfunctions'
import React, { useState, useEffect, useRef,useCallback } from "react";
import styled from "styled-components";
import './style.css';
import ButtonUI from "../button/ButtonUI";
import Segmenter from '../Segmenter';
import Button from 'react-bootstrap/Button';
import WhiteBoard from "../imageEditor/WhiteBoard";
import { PiCursorClickBold } from "react-icons/pi";
import { v4 as uuidv4 } from 'uuid';
import { Client, LocalStream } from 'ion-sdk-js';
import { IonSFUJSONRPCSignal } from 'ion-sdk-js/lib/signal/json-rpc-impl';
// import { connect } from "http2";
import { connectSocket,sendStartTime,socketOpen,joinRoomSend} from "./socket.js";
import Timeline from "./Timeline";
// import { connect } from "http2";
// var Rembrandt = require('rembrandt/build/browser')
// import fs from 'fs';

const Page = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  background-color: white;
  flex-direction: column;
`;


const timeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(/[hms]/).filter(Boolean).map(Number);
  return (hours * 3600) + (minutes * 60) + seconds;
};

// const timeToPercentage = (time, totalVideoTime) => {
//   // const totalVideoTimeInSeconds = totalVideoTimeInHours * 3600;
//   return (timeToSeconds(time) / totalVideoTime) * 100;
// };

const Streamer = (props) => {
    const [yourID, setYourID] = useState();
    const userStream = useRef()
    const imgSrc = useRef('')
    const canvasImage = useRef()
    const maskRef = useRef()
    const [isDrawing, setIsDrawing] = useState(false);
    const largeWhiteboardRef = useRef()
    const [whiteboard, setWhiteBoard] = useState(false)
    // const yourID = useRef()
    const videoRef = useRef();
    const canvasRef = useRef();
    const canvasCombined =useRef()
    const dataarray = useRef(["../assets/data/0h2m29s.png",
    "../assets/data/0h2m29s.npy",
   "../assets/data/mobile_sam.onnx",
   0, null
   ])
    const [notes, setNotes] = React.useState([dataarray.current]);
    // const chatsRef = React.useRef(null);
    // const [chats, setChats] = React.useState(chats_data);
    const [scrollToTime, setScrollToTime] = useState(-1);
    const [isShow, setIsShow] = React.useState(false);
    const [clicks, setClicks] = useState([]);
    const [selected, setSelected] = useState([]);
    const [embeddingPath, setEmbeddingPath] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [progressValue, setProgressValue] = useState(100);
    const [chats, setChats] = React.useState([]);
    // const [scrollToTime, setScrollToTime] = useState(-1);
    const client= useRef()
    const signal = useRef()
    const startTime = useRef(null)
    const [otherthingmoji, setotherThingmoji] = React.useState([]);
    const socketRef = useRef();
    const [values,setValues] = useState([{id:"",label:"Select camera source"}])
    const username = useRef();
    const roomId = useRef();
    const userId = useRef();
    const clusterArray = useRef([]);

    useEffect(() => {

      const href = location.pathname;
      roomId.current = href.split('/').pop();
      // roomId.current = new URLSearchParams(window.location.search).get("roomname")?.split('?username=')[0];
      username.current = new URLSearchParams(window.location.search).get("username")?.split('?id=')[0];
      userId.current = uuidv4();

      socketOpen(true,username,userId)
      joinRoomSend(true,username,userId)
      connectSocket(userId,startTime,chats,setChats,setotherThingmoji,clusterArray)
      console.log("username: ", username.current, "roomname: ", roomId.current);
  
     

  }, []);



      const config = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
    ],
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      for(var i = 0; i < devices.length; i ++){
          var device = devices[i];
          if (device.kind === 'videoinput') {
            var value = device.deviceId
            var label = device.label || 'camera ' + (i + 1)
            // setValues(prevState => [...prevState,  {id: value, label: label}] )
            // values.push([device.deviceId,device.label || 'camera ' + (i + 1)])
            var option = document.createElement('option');
            option.value = device.deviceId;
            console.log(device.deviceId)
            option.text = device.label || 'camera ' + (i + 1);
            document.querySelector('select#videoSource').appendChild(option);
          }
          
          if (device.kind === 'audioinput') {
            var value = device.deviceId
            var label = device.label || 'camera ' + (i + 1)
            // setValues(prevState => [...prevState,  {id: value, label: label}] )
            // values.push([device.deviceId,device.label || 'camera ' + (i + 1)])
            var option = document.createElement('option');
            option.value = device.deviceId;
            console.log(device.deviceId)
            option.text = device.label || 'camera ' + (i + 1);
            document.querySelector('select#audioSource').appendChild(option);
          }
  };
});
  }, []);


  useEffect(() => {
    // signal.current = new IonSFUJSONRPCSignal("ws://localhost:7000/ws");
    signal.current = new IonSFUJSONRPCSignal("wss://server.openmichci.com/ws")
      // signal.current = new IonSFUJSONRPCSignal("wss://thingmoji.com:3001/ws");
    client.current = new Client(signal.current, config);
    // signal.current.onopen = () => client.current.join(roomId.current);
    signal.current.onopen = () => client.current.join(roomId.current);
  
  }, []);

  // useEffect(() => {

  //   // const socket = io.connect("http://localhost:3000",{transports:['websocket'], forceNew: true})
  //   // const socket = io.connect("https://thingmoji.com:5000")
  //   // const socket = io.connect("https://127.0.0.1:5000",{transports:['websocket'], forceNew: true})
    
    
  //   }, []);

  function getCurrentTime() {
    let now = new Date();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();
    return hour + "h" + minute + "m"  + second + "s";
  }

  function gotDevices(deviceInfos) {
    window.deviceInfos = deviceInfos; // make available to console
    var videoSelect = document.getElementById("videoSource")
    Array.from(videoSelect.children).map((child) => child.remove());
    // var audioSelect = document.getElementById("audioSource")
    console.log('Available input and output devices:', deviceInfos);
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      // if (deviceInfo.kind === 'audioinput') {
      //   option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
      //   audioSelect.appendChild(option);
      // } else 
      if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        videoSelect.appendChild(option);
      }
    }
  }
  function getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

    const start = (event) => {
        var videoSelect = document.getElementById("videoSource")
        const videoSource = videoSelect.value;
        setWhiteBoard(true)


      if (event) {
        startTime.current = Date.now()
        sendStartTime(startTime.current)
        LocalStream.getUserMedia({
          resolution: 'vga',
          audio: true,
          codec: "vp8",
          video: {deviceId: videoSource ? {exact: videoSource} : undefined}
          // deviceId: event.target.value
        }).then((media) => {
        videoRef.current.srcObject = media;
        videoRef.current.autoplay = true;
        videoRef.current.controls = true;
        videoRef.current.muted = true;
        console.log(client)
        // if (client===undefined){
        //   client = new Client(signal, config);
        //   client.publish(media);
        // }else{
           client.current.publish(media);
        // }
        // console.log("currenttime",Date.now())

        }).catch(console.error)
        // .then(getDevices).then(gotDevices);
      } else {
        LocalStream.getDisplayMedia({
          resolution: 'vga',
          video: true,
          audio: true,
          codec: "vp8"
        }).then((media) => {
          videoRef.current.srcObject = media;
          videoRef.current.autoplay = true;
          videoRef.current.controls = true;
          videoRef.current.muted = false;
        client.current.publish(media);
        }).catch(console.error);
      }
    }



    // const [thumbnailData, setThumbnailData] = useState(mockData);
    

    // const timeToSeconds = (time) => {
    //   const [hours, minutes, seconds] = time.split(/[hms]/).filter(Boolean).map(Number);
    //   return (hours * 3600) + (minutes * 60) + seconds;
    // };
    
    const timeToPercentage = (time, totalVideoTime) => {
      return (time / totalVideoTime) * 100;
    };



  //   const handleThumbnailClick = (messageId) => {
  //     const dataPoint = mockData.find(data => data.messageId === messageId);
  //     if (dataPoint) {
  //         // Destroy the existing player
  //         if (playerRef.current) {
  //             const playerElement = document.getElementById("twitch-player");
  //             playerElement.innerHTML = ''; // Clear the inner content
  //         }
  
  //         const options = {
  //             width: 800,  // Adjusted width
  //             height: 450, // Adjusted height
  //             video: `1904962428`, // Add timestamp to video URL
  //             parent: ["localhost"],
  //             t: dataPoint.time
  //         };
  //         playerRef.current = new Twitch.Player("twitch-player", options);
  //         setProgressValue(dataPoint.time);
  //         setScrollToTime(dataPoint.time);
  //     }
  // };

  const handleThumbnailClick = (messageId) => {
    const videoElement = videoRef.current;
    const dataPoint = chats.find(data => data.messageId === messageId);
    // if (dataPoint && isFinite(videoElement.duration)) {
    //     const newTime = (dataPoint.time / 100) * videoElement.duration;
    //     videoElement.currentTime = newTime;
    //     const progress = (newTime / videoElement.duration) * 100;
    //     setProgressValue(progress); // Update the progressValue state here
    // }
    if(dataPoint){
      console.log(dataPoint.seconds)
      // setProgressValue(dataPoint.seconds)
      setScrollToTime(dataPoint.messageId)
    }
  };

//   useEffect(() => {
//     const interval = setInterval(() => {
//         setProgressValue(prevValue => Math.min(prevValue + 1, 100)); // Increment by 1 but cap at 100
//     }, 1000); // Every 1 second

//     return () => clearInterval(interval); // Clear the interval when the component is unmounted
// }, []);
  
  

    const CloseButton = (id,index) => {
      document.getElementById("float-box").removeChild(document.getElementById(id))
      deleteNote(index)
      setIsShow(prevState => !prevState);
    }

    const deleteNote = (index) => {
      const newNotes = notes.filter((note) => note[3] !== index-1);
      setNotes(newNotes);
    };
    
    function SegmenterAdd(note,index) {
      // const id = `testsegmenter${index}`
      // if (document.getElementById(id) != null && index!=0){
      //   document.getElementById("float-box").removeChild(document.getElementById(`testsegmenter${index-1}`))
      //   deleteNote(index-1)
      // }
      return (
        <div id="testsegmenter" className="testsegmenter" key={index} style={{position:"absolute",height: "100%", width: "auto",display:"block"}}>
            <div style={{marginLeft:"5px",position:"absolute",zIndex:"250",
            backgroundColor:"rgb(211,211,211,0.5)",borderRadius:"5px",padding:"5px"}}
            >Create a ThingMoji</div>
            <Button size='sm' variant="success" style={{zIndex:"100"}} className="position-absolute top-0 end-0"
            onClick={()=>{CloseButton(id,index)}}> x </Button>
            <img id="image-finded" style={{pointerEvents:"none"}}></img>
            <Segmenter IMAGE_PATH={imagePath}
                  IMAGE_EMBEDDING={embeddingPath}
                  MODEL_DIR={note[2]}
                  id = {index}
                  defaultpoint={selected}
             /> 
        </div>
      );
    }

   
    return (
        <Page id="page">
            <p id="header">ThingMoji</p>
            <div id="videocontainer"> 
            <video autoPlay id="video" ref={videoRef} playsInline></video>

        <Timeline startTime={startTime}
        chats={chats} setScrollToTime={setScrollToTime} clusterArray={clusterArray} videoRef={videoRef}
        isStreamer={true}/>

            {whiteboard &&
            <WhiteBoard whiteboardRef={largeWhiteboardRef} videoRef={videoRef}
            isDrawing={isDrawing} setIsDrawing={setIsDrawing} width={null} height={null} 
            othersize={document.querySelector("#videocontainer")}
            canvasRef = {canvasRef} 
            canvasCombined= {canvasCombined}
            notes={notes} setNotes={setNotes} 
            clicks={clicks} setClicks={setClicks}
            selected={selected} setSelected={setSelected}
            setEmbeddingPath={setEmbeddingPath}
            setImagePath={setImagePath}
            setWhiteBoard={setWhiteBoard}
            username = {username}
            />
          }
          <canvas id="keyframe" ref={canvasRef}></canvas>
          {/* <div id="float-box" style={{display:"none"}}>
          {notes.map(SegmenterAdd)}          
            <canvas style={{visibility:"hidden",display:"absolute",pointerEvents:"none"}} ref={canvasCombined}></canvas>
          </div>
          <div id="float-thingmoji" 
          onMouseEnter={()=>document.getElementById("modify").style.display="block"}
          onMouseLeave={()=>document.getElementById("modify").style.display="none"}
          >
            <div id="modify" 
            style={{display:"none"}}
            ><PiCursorClickBold />Click to Modify!</div>
          </div> */}
            </div>
       
            <div>
            <ButtonUI whiteboard={whiteboard}
            setWhiteBoard={setWhiteBoard}           
            LocalStream = {LocalStream} start={start}
            videoRef ={videoRef} client={client} startTime={startTime}
            values={values} setValues={setValues}
            isStreamer={true}/>
            {/* start={start} /> */}
                </div>
            <div id='chatboxcontainer'>
              
            <Chatbox yourID={yourID} 
            username={username} userId={userId}
            chats={chats} setChats={setChats} scrollToTime={scrollToTime}
            setotherThingmoji={setotherThingmoji} otherthingmoji={otherthingmoji}
            socketRef={socketRef} 
            isStreamer = {true}
            notes={notes}
            canvasCombined={canvasCombined}
            // selected={selected}
            selected={selected} setSelected={setSelected}
            embeddingPath={embeddingPath}
            imagePath={imagePath}
            clusterArray={clusterArray}
            />    
            {/* <Chatbox yourID={yourID} notes={notes} setNotes={setNotes} chatsRef={chatsRef}/>  */}
            </div>
        </Page>
    )
}

export default Streamer;