import React, { useEffect } from "react";
// import Router from "react-router-dom";
import { useState } from "react";

// import { v1 as uuid } from "uuid";
import LoginForm from "./LoginForm";
// import useTheme from "../imageEditor/useTheme";
import {socketOpenLobby,messageSocketLobby} from "./socket";
import { v4 as uuidv4 } from 'uuid';

const CreateRoom = (props) => {

    const streamer = () => {
        // const id = uuid();
        props.history.push(`/streamer`);
    }

    const viewer = () => {
        // const id = uuid();
        props.history.push(`/viewer`);
    }

    const customer = () => {
        props.history.push(`/customer`);
    }

    const [username, setUsername] = useState(null);
    const [roomname, setRoomname] = useState(null);
    // const [colorTheme, setTheme] = useTheme();

    // const changeTheme = () => {
    //   setTheme(colorTheme)
    // }
   
    const checkViewerPw = () => {
      // gets the current input value
      const answer = document.getElementById("password").value;
    
      if (answer === "viewer2023!") { 
        // setIsVerified(true);
        props.history.push(`viewer/${roomname}?username=${username}?id=${uuidv4()}`);
      } else {
        alert("Sorry, the password is incorrect for Viewer");
      }
    };

    const checkStreamerPw = () => {
      // gets the current input value
      const answer = document.getElementById("password").value;
           
      if (answer === "streamer2023!") { 
        // setIsVerified(true);
        props.history.push(`streamer/${roomname}?username=${username}?id=${uuidv4()}`);
        // props.history.push(`streamer`);
      } else {
        alert("Sorry, the password is incorrect for Streamer");
      }


    };

    // useEffect(() => {
    //   socketOpenLobby()
    //   messageSocketLobby()
    //   // connect
    // }, []);


    return (
        <div>       
            {/* <button onClick={streamer} className={CreateRoomStyle.button}>Create Stream</button>
            <button onClick={viewer} className={CreateRoomStyle.button}>Join Stream</button>
            <button onClick={customer} className={CreateRoomStyle.button}>Join Customer</button> */}
            {/* <div id="id01" className="modal">
  
  <form className="modal-content animate" method="post">
    <div className="imgcontainer">
      <span className="close" title="Close Modal">&times;</span>
    </div>

    <div className="container">
      <label htmlFor="uname"><b>Username</b></label>
      <input type="text" placeholder="Enter Username" name="uname" required/>

      <label htmlFor="psw"><b>Password</b></label>
      <input type="password" placeholder="Enter Password" name="psw" required/>
        
      <button type="submit">Login</button>
      <label>
        <input type="checkbox" name="remember"/> Remember me
      </label>
    </div>

    <div className="container" style={{backgroundColor:"#f1f1f1"}}>
      <button type="button" className="cancelbtn">Cancel</button>
      <span className="psw">Forgot <a href="#">password?</a></span>
    </div>
  </form>
</div> */}
<LoginForm setUsername={setUsername} setRoomname={setRoomname} RoomName={roomname}  checkViewerPw={checkViewerPw}
checkStreamerPw={checkStreamerPw}/>

        </div> 
    );
}

export default CreateRoom;