

//test histogram in javascript
function drawImageOnCanvas(image) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    return context;
}

function computeHistogram(image) {
    const context = drawImageOnCanvas(image);
    const imageData = context.getImageData(0, 0, image.width, image.height);
    const data = imageData.data;

    let histogramR = new Array(256).fill(0);
    let histogramG = new Array(256).fill(0);
    let histogramB = new Array(256).fill(0);

    for (let i = 0; i < data.length; i += 4) {
        histogramR[data[i]]++;     // Red channel
        histogramG[data[i + 1]]++; // Green channel
        histogramB[data[i + 2]]++; // Blue channel
    }

    return { histogramR, histogramG, histogramB };
}

function normalizeHistogram(histogram) {
    const maxVal = Math.max(...histogram);
    return histogram.map(val => val / maxVal);
}

export function getNormalizedHistograms(image) {
    const { histogramR, histogramG, histogramB } = computeHistogram(image);
    return {
        histogramR: normalizeHistogram(histogramR),
        histogramG: normalizeHistogram(histogramG),
        histogramB: normalizeHistogram(histogramB)
    };
}

export const handleThingmojiDoubleClick = (message,setMessage,imageSrc,originImageSrc) => {
    // console.log(item,'---------------item--------------------')
    // const imageSrc = item.resizedImage.src;
    // const originImageSrc = item.originalImage.src; 
    // console.log("messageprior",message)
    setMessage(prevState => {
      console.log(prevState)
      const texts=prevState?.texts || [];
      const images=prevState?.images || [];
      const index = texts.length+images.length;
      return (
        {
          texts,
          images: [...images,{
            image: imageSrc,
            originalImage: originImageSrc,
            index: index
          }],
          index: index+1
        }
      )
    });
   
  };
