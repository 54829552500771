import React, { Component, useEffect, useRef,useCallback,useState} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import './Chat.css';
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { MdAccessTime } from "react-icons/md";
import Message from './Message_new';
import Segmenter from '../Segmenter';
// import ImageLoader from '../ImageLoader'
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Navbar, Nav, CloseButton } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import 'bootstrap/dist/css/bootstrap.min.css';
import { PiCursorClickBold } from "react-icons/pi";
import AppContext from "../components/hooks/createContext";
import { messageSocket,messageSend,thingmojiSocket} from '../routes/socket';
import { all } from 'axios';
import {getStream,capture} from '../routes/chatfunctions'
// import html2canvas from 'html2canvas';
import { pick } from 'underscore';
import MessageInput from './MessageInput';
import { getNormalizedHistograms } from './histgram';
import { IconContext } from "react-icons";




const MessageImage = styled.img`
    width: 40px;  // You can adjust this as needed
    height: auto;
    margin-top: 10px;
    border-radius: 10px;
`;

const MessageImageContainer = styled.div`
    position: relative;
    cursor: pointer;
    display: inline-block;
    &:hover > div {
        display: block;
    }
`;

// const MessageImage = styled.img`
//     width: 100px;
//     height: auto;
//     margin-top: 10px;
//     border-radius: 10px;
// `;
const OriginalImageBubble = styled.div`
    display: none;
    position: absolute;
    padding: 10px;
    z-index: 100;
    right:0%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
     border-radius: 10px;
     background-color: white;
    &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: 10%;
        transform: translateX(-50%);
        rotate: 180deg;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid white;

    }
`;


const thingmoji_group = [
    //  "Group 1",
     <i key={0}> <IconContext.Provider value={{ size: '20px', color:"red"}}><HiMiniRectangleGroup/></IconContext.Provider></i>,
     <i key={1}> <IconContext.Provider value={{ size: '20px', color:"blue"}}><HiMiniRectangleGroup/></IconContext.Provider></i>,
     <i key={2}> <IconContext.Provider value={{ size: '20px', color:"green"}}><HiMiniRectangleGroup/></IconContext.Provider></i>,
     
];
const thingmoji_data = {

};


const chats_data = []

const Chatbox = ({yourID,username,userId,chats,setChats,scrollToTime,otherthingmoji,setotherThingmoji,socketRef,isStreamer,notes
,selected,embeddingPath,imagePath,canvasCombined}) => {
    const chatsRef = React.useRef(null);
    const [tooltipID, setTooltipID] = React.useState(-1);
    const chatRefs = React.useRef([]);

     const tooltip = (src)=>{
      
      return (<Tooltip id="tooltip" style={{padding: 0, display: "inline-block"}}>
        <Button variant='outline-secondary' size='sm' onClick={(e) => handleDelete(tooltipID)}
          className='shadow-none font' style={{ width: "100px", height: "100px", fontFamily: "Pacifico, cursive"}}>
          <img src={src}></img>
        </Button>
      </Tooltip>
    );
      }

      // const tooltip = (
      //   (<Tooltip id="tooltip" style={{padding: 0, width: 50, display: "block"}}>
      //     <Button variant='outline-secondary' size='sm' onClick={(e) => handleDelete(tooltipID)}
      //       className='shadow-none font' style={{ width: 30, fontFamily: "Pacifico, cursive"}}>
      //       x
      //     </Button>
      //   </Tooltip>)
      // );
    
    
    // const [message, setMessage] = React.useState({ text: "", image: null });
    // const [message, setMessage] = React.useState({ texts: [], images: [], index:0 });
    const {message: [message, setMessage]} = React.useContext(AppContext);
    const {thingmoji: [thingmojiItem, setThingmojiItem]} = React.useContext(AppContext);
    const {intervalthingmoji: intervalthingmoji} = React.useContext(AppContext);
    
    const [isShowThingmoji, setIsShowThingmoji] = React.useState(false);
    const [thingmojiIndex, setThingmojiIndex] = React.useState(-1);
    const [groupIndex, setGroupIndex] = React.useState(4);
    const [allIsClicked, setAllIsClicked] = React.useState(true);

    const [thingmojiGroup, setThingmojiGroup] = React.useState(thingmoji_group);
    const [thingmoji, setThingmoji] = React.useState(thingmoji_data);
    // const [content, setContent] = useState({ __html: '' }); 

    

    const [event, setEvent] = React.useState();
    const update = React.useRef(false)

    const [position, setPosition] = React.useState({ top: 0, left: 0 });
    // const socketRef = socketID()
    
    const handleDelete = (id) => {
      setAllThingmoji(prevState => prevState.filter((item, index) => index !== id));
    };
    // const [update, setupdate] = React.useState(false);
    useEffect(() => {

      // socketRef.current = socketID()
      if (scrollToTime !== -1) {
        console.log("find the message")
          scrollToMessageByTime(scrollToTime);
      }
    }, [scrollToTime]);


    useEffect(()=>{
      //scroll down to the bottom for chatsRef.current
      scrollToBot();
      
    },[chats.length]);

    // useEffect(()=>{
    //     // scrollToBot();
    //     // chatRefs.current[-1].scrollIntoView({ behavior: 'smooth' });
    //     //receive messages
    //     messageSocket(receivedMessage);
    //     // thingmojiSocket(otherThingMojiMessage)
     
        
    // },[]);

    function getCurrentSecond() {
      return Date.now();
    }

    const scrollToMessageByTime = (targetId) => {
      console.log(targetId)
      const targetIndex = chats.findIndex(chat => chat.messageId === targetId);
      if (targetIndex !== -1 && chatRefs.current[targetIndex]) {
        console.log(targetIndex)
        console.log(chatRefs.current[targetIndex]);
          chatRefs.current[targetIndex].scrollIntoView({ behavior: 'smooth' });
          chatRefs.current[targetIndex].children[1].style.border = '2px solid red';
          chatRefs.current[targetIndex].children[1].style.backgroundColor = 'lightpink';
      } else {
      }
  };

    const scrollToBot = () => {
        // ScrollHeight property returns the entire height of an element in pixels
        // scrollTop sets or return the number of pixels an element content is scrolled vertically.
        // console.log(ReactDOM.findDOMNode(chatsRef.current))
        // console.log(ReactDOM.findDOMNode(chatsRef.current).scrollHeight)
        ReactDOM.findDOMNode(chatsRef.current).scrollTop = ReactDOM.findDOMNode(chatsRef.current).scrollHeight;
    }


    const handleThingmojiDoubleClick = (imageSrc,originImageSrc) => {
      // console.log(item,'---------------item--------------------')
      // const imageSrc = item.resizedImage.src;
      // const originImageSrc = item.originalImage.src; 
      // console.log(id)
      setMessage(prevState => {
        console.log(prevState)
        const texts=prevState?.texts || [];
        const images=prevState?.images || [];
        const index = texts.length+images.length;
        return (
          {
            texts,
            images: [...images, {
              image: imageSrc,
              originalImage: originImageSrc,
              index: index,
              // id:id
            }],
            index: index+1
          }
        )
      });
      console.log(message)
    };


    const deleteGroup = () => {
      if(window.confirm("Delete it?")){
        let copy_thingmoji = thingmoji;
        delete copy_thingmoji["Group " + String(groupIndex-1)];
        // "All" will be clicked automatically after a "delete" event
        // this.setState({thingmoji_index: -1, all_is_clicked: true});
        if(groupIndex > 1){
            setThingmoji(copy_thingmoji);
            setThingmojiGroup(thingmojiGroup.slice(0, groupIndex-2));
            setGroupIndex(prev=>prev-1);
        }
        else {
          window.alert("No group can be deleted.");
        }
      }
    }
    const newGroup = () => {
      let group = prompt("Please enter a name for the new group.", "Group " + String(groupIndex));
      if(group === "All") {
        window.alert('Using "All" as the group name is not allowed.');
        return;
      }
      if(thingmojiGroup.includes(group)) {
        window.alert("The group has existed already, please use other names.");
        return;
      }
      if (group != null && group != "") {
        let copy_thingmoji = thingmoji;
        // "All" will be clicked automatically after a "new" event
        // this.setState({thingmoji_index: -1, all_is_clicked: true});
        copy_thingmoji["Group " + String(groupIndex)] = [];
        setThingmoji(copy_thingmoji);
        // setThingmojiGroup(prev => [...prev, "Group " + String(groupIndex)])
        setThingmojiGroup(prev => [...prev, group]);
        setGroupIndex(prev=>prev+1);
      }
    }
    const updateEvent = (e) => {
      setEvent(e);
    }




  const sendMessage = (e) => {
    e.preventDefault();
    const textcontent = document.getElementById("contentEditable");
            
    if (textcontent.children.length > 0 || textcontent.textContent.length > 0) {
        const messageData = {
          type: "chatMessage",
          data:{
              texts: message.texts,
              username: username.current,
              userId: userId.current,
              hasImage: !!message.images,
              images: message.images,
              // time: getCurrentTime(),
              seconds: getCurrentSecond(),
              index: message.index,
              isStreamer: isStreamer,
          }
          // originImage: message.originImage // Adding the originImage property
      };
    
    
      messageSend(messageData);

      setMessage({ texts: [], images: [], index:0 });
      setIsShowThingmoji(false);
    }
    // Send the message
}


  
    const handleChange = (e) => {
      e.preventDefault();
      setMessage(e.target.value);
    }

    const receivedMessage = (messageData) => {
      // if (messageData.id !== yourID) {
        if (messageData.hasImage) {
            setChats(prev => [...prev, {
              username: messageData.username,
              userId: messageData.userId,
              texts: messageData.texts,
              images: messageData?.images,
              time: messageData.time,
              seconds: messageData.seconds,
              messageId: messageData.messageId,
              index: messageData.index,
              isStreamer: messageData.isStreamer
              // originImage: messageData?.originImage,
          }]);


        } else {
            setChats(prev => [...prev, {
                username: messageData.username,
                userId: messageData.userId,
                texts: messageData.texts,
                // img: "../assets/image/head.png",
                images: [],
                // originImage: [],
                time: messageData.time,
                seconds: messageData.seconds,
                messageId: messageData.messageId,
                index: messageData.index,
                isStreamer: messageData.isStreamer
            }]);
            const clatlists = document.getElementById("chatlists")
            // clatlists.scrollTop = clatlists.scrollHeight;
            // console.log(clatlists.scrollHeight)
            
            // clatlists.scrollIntoView({ behavior: "smooth", block: "end" })
        }
        ReactDOM.findDOMNode(chatsRef.current).scrollTop = ReactDOM.findDOMNode(chatsRef.current).scrollHeight;
            // ReactDOM.findDOMNode(chatsRef.current).scrollIntoView({ behavior: "smooth", block: "end" })
      // }
  }
  
  

  const otherThingMojiMessage = (messageData) => {
    setotherThingmoji(prevState => [...prevState, messageData]);
    console.log(messageData)
    // setAThingmoji(allThingmoji ? [...allThingmoji, newItem] : [newItem]);
    console.log(otherthingmoji)
    console.log(currentGroupedThingmoji)
  }

    const cancelTooltip  = () => {
      allThingmoji.map((tooltipID) => {
        setTooltipID(-1);
      })
    }

    const controlTooltip = (e, id) => {
      e.stopPropagation();
      if (tooltipID === id) {
        setTooltipID(-1);
      } else {
        setTooltipID(id);
      }
    }

    const handleDragStart = (e, id) => {
      e.dataTransfer.setData('imageId', id);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e, group) => {
      e.preventDefault();
      const imageId = e.dataTransfer.getData('imageId');
      allThingmoji[imageId].group = group;
      setThingmojiIndex(group);
    };
    

    // const username=yourID;
    const {allThingmoji: [allThingmoji, setAllThingmoji]} = React.useContext(AppContext);
    // const [currentThingmoji, setCurrentThingmoji] = React.useState(allThingmoji);
    const currentGroupedThingmoji = allThingmoji.filter((image, id) => {
      // handleThingmojiDoubleClick(image.resizedImage.src, image.originalImage.src)
        return image?.group === thingmojiIndex || thingmojiIndex === -1;
    });

    function SegmenterAdd(note,index) {
      // const id = `testsegmenter${index}`
      // if (document.getElementById(id) != null && index!=0){
      //   document.getElementById("float-box").removeChild(document.getElementById(`testsegmenter${index-1}`))
      //   deleteNote(index-1)
      // }
      return (
        <div id="testsegmenter" className="testsegmenter" key={index} style={{position:"absolute",height: "100%", width: "100%",display:"block",borderRadius:"5%"}}>
            <div style={{marginLeft:"5px",position:"relative",zIndex:"250", width:"200px",
            backgroundColor:"rgb(211,211,211,0.5)",borderRadius:"5px",padding:"5px",pointerEvents:"none"}}
            >Modify a ThingMoji</div>
            {/* <Button size='sm' variant="success" style={{zIndex:"100"}} className="position-absolute top-0 end-0"
            onClick={()=>{CloseButton(id,index)}}> x </Button> */}
            <img id="image-finded" style={{pointerEvents:"none"}}></img>'
            {/* <PeekWindow></PeekWindow> */}
            <Segmenter IMAGE_PATH={imagePath}
                  IMAGE_EMBEDDING={embeddingPath}
                  MODEL_DIR={note[2]}
                  id = {index}
                  defaultpoint={selected}
                  // intervalthingmoji={intervalthingmoji}
             /> 
        </div>
      );
    }

    function handleMove(e){ 

      const targetImage = e.target.src;
      let left = e.pageX-document.getElementById("gallery").getBoundingClientRect().left-30;
      //  console.log(left)

      let top
      const image_test = document.getElementById("image-enlarge");
      if (image_test!==null){
           image_test.src = targetImage;
      }

      if (e.pageY+120>document.getElementById("gallery").getBoundingClientRect().bottom){
        top = e.pageY-150;
      }else{
        top = e.pageY+40;
      }
      // if (e.pageX+80>document.getElementById("gallery").getBoundingClientRect().right){
      //   left = e.pageX-80-document.getElementById("gallery").getBoundingClientRect().left;
      // }

       setPosition({ top, left })
    }



    return (
        <div id="chatroom" className="chatroom" onClick={cancelTooltip}
        >
          <h3>CHAT</h3>
          <ul id = "chatlists" className="chats" ref={chatsRef}>
            {
              chats.map((chat, index) =>
              //  <span key={index}>
                <Message key={index} chat={chat} user={userId.current} ref={el => chatRefs.current[index] = el}/>
                // </span>
              )
            }
          </ul>
          {/* <form className="input" onSubmit={(e) => submitMessage(e)}> */}
          

        <div className='input' style={{position:"absolute",bottom:"0px",width:"100%",display:"block"}}>
          <div style={{position:"relative",bottom:"10px"}}>
            <div id="float-box" style={{display:"none",overflow:"hidden"}}>
              {notes.map(SegmenterAdd)}          
                <canvas style={{visibility:"hidden",display:"absolute",pointerEvents:"none"}} ref={canvasCombined}></canvas>

              </div>
              <div id="float-loading" className="float-thingmoji" style={{zIndex:"1000"}}
              >
                <img src="../assets/loading.gif" width="50px" height="50px" style={{position:"absolute",left:"50%",top:"50%",transform:"translate(-50%,-50%)"}}></img>
              </div>
              <div id="float-thingmoji" className="float-thingmoji" style={{zIndex:"999"}}
              // className='fadeOut animated'
              // className='jiggle'
              // onMouseEnter={()=>document.getElementById("modify").style.display="block"}
              // onMouseLeave={()=>document.getElementById("modify").style.display="none"}
              >
                {/* <span id="float-span" onClick={(e)=>{
                  e.preventDefault()
                  handleThingmojiDoubleClick(thingmojiItem.resizedImage.src, thingmojiItem.originalImage.src)
                  document.getElementById("contentEditable")?.focus();
                  const floatThingmoji = document.getElementById("float-thingmoji");
                  if (floatThingmoji) {
                      floatThingmoji.style.display = "none";
                  }
                  clearTimeout(intervalthingmoji);
                }}></span> */}
                <div id="modify" 
                style={{display:"none"}}
                ><PiCursorClickBold />Click to Modify!</div>
              </div>
          </div>
          {/* </div> */}
          <form onSubmit={sendMessage} style={{display:"inline-block",width:"100%",minHeight:"40px",maxHeight:"200px",position:"relative",margin:"3px"}}>
            {/* <input type="text" value={message.text} placeholder="Message" onChange={(e) => setMessage({ ...message, text: e.target.value })}/> */}

            <MessageInput message={message} setMessage={setMessage} chat={chats} setChats={setChats}
            username = {username} userId={userId} isStreamer={isStreamer}
           />
            {/* {message.image && <img src={message.image} alt="Selected thingmoji" style={{ width: '50px', height: '50px', marginRight: '10px' }} />} */}
            {/* <img src='https://cdn.iconscout.com/icon/premium/png-512-thumb/screenshot-2858923-2380418.png?f=avif&w=512' type="popup" onClick={()=>check()} /> */}
           <div style={{display:"inline-block",width:"30%",position:"absolute",left:"70%"}}>
            <input type="button" id="thingmoji-btn" value="Gallery" 
             className="default-button off" style={{position:"relative",display:"inline-block"}}
              onClick={()=>{setIsShowThingmoji(prev=>!prev)}} />
            <button type="submit" id="button" value="Send" 
             className="default-button off"  style={{position:"relative",display:"inline-block"}}
            >Send</button>
            </div>
          </form>
        </div>
          <div className="popup" id= "gallery" style={{display: isShowThingmoji?'block':'none'}}>
            <Navbar bg="light" variant='light' style={{width:"65px",display:"inline-block",verticalAlign:"top"}}>
              <form className="form-inline" 
                //         onMouseEnter={() => setTooltipID(0)} 
                //  onMouseLeave={() => setTooltipID(null)}
                >
              <Button variant='outline-secondary' size='sm' style={{margin:1, width:60, height:30, overflow:"hidden", fontFamily:"Pacifico, cursive"}}
                className='shadow-none font'
                onClick={() => {setThingmojiIndex(-1); setAllIsClicked(true)}}
                active={thingmojiIndex===-1} 
                >
                <HiMiniRectangleGroup /> All
              </Button>
              {thingmojiGroup.map((content,index) => 
                <Button variant='outline-secondary' size='sm' style={{margin:1, width:60, height:30, overflow:"hidden", fontFamily:"Pacifico, cursive"}}
                key={index}
                className='shadow-none font'
                onClick={()=>{setThingmojiIndex(index); setAllIsClicked(false)}}
                onDrop={(e)=>{handleDrop(e,index)}} 
                onDragOver={handleDragOver}
                active={thingmojiIndex===index}>
              
                {content}
                </Button>
              )}
              </form>
            </Navbar>
            <div id="thingmoji-container" style={{display:"inline-block",width:"75%",marginTop:"5px"}}>
            <div style={{fontSize:"14px",color:"darkgray",marginLeft:"2px"}}><MdOutlineAccessTimeFilled /> My Thingmoji <RiArrowDropDownLine /></div>
            {currentGroupedThingmoji.map((item, id) => {
            return(
              <div className="thingmoji-container" key={id}>
                {/* <OverlayTrigger key={id} placement="bottom" show={id===tooltipID} overlay={tooltip(item.resizedImage.src)}> */}
                  <div style={{width:"50px",height:"50px"}} className="thingmoji group">
                    <img draggable="true" onDragStart={(e) => handleDragStart(e, id)}
                        key={item.resizedImage.src} 
                        // id = {`mythingmoji${id}`}
                      src={item.resizedImage.src} width={45} height={45} onClick={(e) => {
                        e.preventDefault()
                        handleThingmojiDoubleClick(item.resizedImage.src, item.originalImage.src)
                      }
                      }
                      id={`mythingmoji_${id}`} 
                      onMouseEnter={(e) => 
                        {
                          e.preventDefault() 
                          setTooltipID(id)}} 
                      onMouseMove = {(e) => handleMove(e)}
                      onMouseLeave={(e) => {
                        e.preventDefault()
                        setTooltipID(null)}}
                      />
                      <span style={{position:"absolute", right:"0px",bottom:"0px"}}
                      onClick={(e) => handleDelete(id)}>x</span>
                  </div> 
                  {/* {id===tooltipID &&
                    <div id="image-peek" style={{ top: position.top, left: position.left}}
                    >
                        <img src={item.resizedImage.src} style={{width:"80px",height:"80px",
                       }}
                        ></img>
                    </div>
                    } */}
              </div>
            )})}
    <div style={{fontSize:"14px",color:"darkgray",marginLeft:"2px",display:"inline-block",width:"100%"}}><MdOutlineAccessTimeFilled /> All ThingMojis <RiArrowDropDownLine /></div>
        {/* //thingmoji created by other people */}
        {otherthingmoji.map((item, id) =>  {
                    return(
          <div className="thingmoji-container" key={id}
              
                >
              <div style={{width:"50px",height:"50px"}} className="thingmoji group">
                  <img draggable="true" onDragStart={(e) => handleDragStart(e, id)}
                    key={item.resizedImage}
                    id = {`allthingmojis${id}`}
                    src={item.resizedImage} width={45} height={45} onClick={(e) => {
                      e.preventDefault()
                      handleThingmojiDoubleClick(item.resizedImage, item.originalImage)
                    }}
                    onMouseEnter={() => setTooltipID(`otherthingmoji${id}`)}
                    onMouseMove = {(e) => handleMove(e)}
                    onMouseLeave={() => setTooltipID(null)}
                    />  
              </div>
              {/* {`otherthingmoji${id}`===tooltipID &&
                    <div id="image-peek" style={{ top: position.top, left: position.left}}
                    >
                        <img src={item.resizedImage} style={{width:"80px",height:"80px",
                       }}
                        ></img>
                    </div>
                    } */}
           </div>
        )})}


            </div>
          </div>
          
         {tooltipID!==null && tooltipID!==-1 &&
                    <div id="image-peek" style={{ top: position.top, left: position.left}}>
                        <img id="image-enlarge" style={{width:"80px",height:"80px" }}></img>
                    </div>
        }
        </div>
      );
}

export default Chatbox;