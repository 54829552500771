import e from 'cors';
import React, { useEffect, useState, useRef } from 'react';
import { ChatRight, EmojiSmileUpsideDown } from 'react-bootstrap-icons';
import styled from 'styled-components';

const MessageContainer = styled.li`
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.isUser ? 'flex-end' : 'flex-start')};
    margin-bottom: 15px;
    max-width: 350px;
`;


const UserName = styled.span`
    font-size: 0.9em; 
    margin: 0 0 5px 10px; 
    align-self: flex-start;
    color: ${props => (props.isStreamer ? '#007BFF' : '#FFF')};
`;

const MessageContent = styled.div`
    background-color: ${props => (props.isUser ? '#DCF8C6' : '#FFF')};
    padding: 8px 12px;
    border-radius: 18px;
    max-width: 100%;
    margin: 0;
`;

const MessageTime = styled.span`
    font-size: 0.8em;
    color: #888;
    margin-top: 5px;
    text-align: right;
`;

const MessageImageContainer = styled.div`
    position: relative;
    cursor: pointer;
    display: inline-block;
    &:hover > div {
        display: block;
    }
`;


const MessageImage = styled.img`
    width: 30px; 
    height: 30px; 
    margin: 0 5px;
    border-radius: 10px;
    vertical-align: middle; 
    z-index: 100;
`;

const OriginalImageBubble = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    background-color: black;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
    overflow: auto;

    img {
        width: 100%;
        height: auto;
    }
`;

const OriginalImageBubbleTool = styled.div`
    display:none;
    position: absolute; 
    transform: translateX(-50%);
    width: 120px; 
    height: auto;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
`;







const Message = React.forwardRef(({chat,user}, ref) => {
    const isUser = chat?.userId === user;
    const [showOriginalImage, setShowOriginalImage] = useState(null);
    const bubbleRef = useRef(null);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
                setShowOriginalImage(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const [position, setPosition] = React.useState({ top: 0, left: 0 });
    const imageContainerRef = React.useRef(null);

    React.useEffect(() => {
        if (imageContainerRef.current) {
            const handleMouseMove = (event) => {
                event.stopPropagation();
                event.preventDefault();

                    const boundingBox = imageContainerRef.current.getBoundingClientRect();
                    const chatboxBox = document.querySelector('.chatroom').getBoundingClientRect();
                    const bubble = document.querySelector(":root")
                    // let top = event.clientY - boundingBox.top - 120; // Adjust based on the bubble height
                    let left = event.clientX - boundingBox.left; // Adjust based on half the bubble width
                    // bubble?.style.setProperty("--pseudo-border-bottom", '');
                    // bubble?.style.setProperty("--pseudo-border-top", '10px solid white');
                    // bubble?.style.setProperty("--pseudo-top", '');
                    // bubble?.style.setProperty("--pseudo-bottom", '-10px');
                    // Ensure the bubble doesn't go outside the chatbox
                    // console.log("moving")
                    // if (event.clientY - chatboxBox.top < 500) {
                        // console.log("added")
                        let top

                        // const after = bubble.querySelector( "::after" );
                        // console.log(after)
                        // console.log(after.style)    
                    // }
                    if (chatboxBox.bottom - event.clientY < 200) {
                        top = event.clientY - boundingBox.top - 150;
                        bubble?.style.setProperty("--pseudo-border-bottom", '');
                        bubble?.style.setProperty("--pseudo-border-top", '10px solid white');
                        bubble?.style.setProperty("--pseudo-top", '');
                        bubble?.style.setProperty("--pseudo-bottom", '-10px');
                        // console.log("bottom")
                    }else{
                        top = event.clientY - boundingBox.top + 50;
                        // console.log("image-bubble-"+event.target.id)
                        
                        bubble?.style.setProperty("--pseudo-border-bottom", '10px solid white');
                        bubble?.style.setProperty("--pseudo-border-top", '');
                        bubble?.style.setProperty("--pseudo-top", '-10px');
                        bubble?.style.setProperty("--pseudo-bottom", '');
                        // console.log("top")

                    }

                // if (event.clientX - chatboxBox.left < 75) {
                //     left = event.clientX - boundingBox.left + 50;
                // }
                // if (chatboxBox.right - event.clientX < 75) {
                //     left = event.clientX - boundingBox.left - 50;
                // }


                    // if (event.clientX - chatboxBox.left < 75) {
                    //     left = event.clientX - boundingBox.left;
                    // }
                    // if (chatboxBox.right - event.clientX < 75) {
                    //     left = event.clientX - boundingBox.left - 50;
                    // }

                    setPosition({ top, left });
            };

            // if (hover){
                imageContainerRef.current.addEventListener('mousemove', handleMouseMove);

            return () => {
                if (imageContainerRef.current!==null ){

                imageContainerRef.current.removeEventListener('mousemove', handleMouseMove);
                }
            };
        }
    }, []);


    const buildContent = (chat) => {
    try{
        let newContent = [];
        const maxLength = chat.index;
        const messageId = chat.messageId;
        for (let i = 0; i < maxLength; i++) {
        //    const currentIndex = chat.texts.filter((text)=>text.index === i)||chat.images.filter((image)=>image.index === i)
            if (chat.texts.findIndex((text)=>text.index === i)!==-1) {
                const index = chat.texts.findIndex((text)=>text.index === i)
                newContent.push(<span id="textspan" key={`text-${index}`}>{chat.texts[index].content}</span>);
            }
            
            if (chat.images.findIndex((image)=>image.index === i)!==-1) {
                const index = chat.images.findIndex((image)=>image.index === i)
                pushImage(chat,newContent,index,messageId);
            }
        }

        return newContent;
    }catch(e){
        console.log(e)
    }
}


function pushImage(chat,newContent,i,messageId){
    // console.log(chat.images[i])
    newContent.push(
        <span key={`image-container-${i}`}   onMouseEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setHover(true);
            // console.log("enter")
            // setShowOriginalImage(chat.images[i].originalImage);
        }}
        onMouseLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // setPosition({ top:0, left:0 });
            setHover(false);
            // console.log("leave")
        }} >
        <MessageImageContainer 
            onClick={(e) => {
                e.stopPropagation();
                // setShowOriginalImage(null);
                // console.log(showOriginalImage)
                setShowOriginalImage(chat.images[i].originalImage);
                // bubbleRef.current.classList.add('moveforward');
            }} 
            ref={imageContainerRef}
            id={`m${messageId}-${i}`}
          
        >
            <MessageImage id={`m${messageId}-${i}`} src={chat.images[i].image} alt={`Image-${i}`} 
                       />
            {hover &&  chat.images[i].originalImage
            ? (
            <OriginalImageBubbleTool 
            style={{ top: position.top, left: position.left}}
            // style={{ top: document.getElementById(`m${messageId}-${i}`).getBoundingClientRect().top+50, left: document.getElementById(`m${messageId}-${i}`).getBoundingClientRect().left-50}}
            id={`image-bubble-m${messageId}-${i}`}
            className = "image-bubble"
            >
            <img src={chat.images[i].image} alt={`Image-${i}`} style={{ width: '100px',height:'100px' }} />
            </OriginalImageBubbleTool>
             ):null}
        </MessageImageContainer>
            
            {chat.images[i].originalImage && showOriginalImage === chat.images[i].originalImage ? (
                <OriginalImageBubble ref={bubbleRef}>
                    <img id={`original-${i}`} src={chat.images[i].originalImage} alt="Original Image" onClick={() => setShowOriginalImage(null)} 
                              className='original-image'
                              style={{ width: '100%', height: 'auto', draggable: 'false' }}
                              onWheel={(e) => {
                                    // e.stopPropagation();
                                    console.log("onscroll")
                                    //make it larger when scroll up
                                    // setInterval(()=>{console.log("setInterval")},1000)
                                    document.getElementById(`original-${i}`).style.transform = `scale(${e.deltaY > 0 ? 0.9 : 1.5})`;
                                    // setShowOriginalImage(chat.images[i].originalImage);
                                }}/>
                </OriginalImageBubble>
            ):null}
        </span>
    );
}

    return (
        <MessageContainer ref={ref} isUser={false}>
            <UserName isStreamer={chat.isStreamer}>{chat.username}</UserName>
            <MessageContent isUser={false}>
                {buildContent(chat)}
            </MessageContent >
            <MessageTime>{chat.time}</MessageTime>
        </MessageContainer>
            // <MessageContainer ref={ref} isUser={isUser}>
            //     {!isUser && <UserName>{chat.username}</UserName>}
            //     <MessageContent isUser={isUser}>
            //         {buildContent(chat)}
            //     </MessageContent>
            //     <MessageTime>{chat.time}</MessageTime>
            // </MessageContainer>
    );
});

export default Message;