import io from "socket.io-client";
import ReactDOM from 'react-dom';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import React, { useState, useEffect, useRef } from "react";

// const socket = io.connect("http://localhost:8000");

// const socket =  new WebSocket('ws://localhost:5000/ws');
const socket =  new WebSocket('wss://socket.openmichci.com/ws');


export const socketOpenLobby = ()=>{
    socket.onopen = () => {
        console.log('WebSocket Client Connected!!!!!!!');
        console.log(socket.readyState)
        const message = {
                type: "joinLobby",
                // data: {
                //     // username: username.current,
                //     // userId: userId.current,
                //     // isStreamer: isStreamer,
                //     //   roomID: roomId.current,
                //     //   id: userId.current
                // }
        }
        socket.send(JSON.stringify(message))
        };
        socket.onerror = function() {
            console.log('Connection Error');
          };
        
        
        // socketRef.current.onmessage = (message) => {
        //   console.log(message);
        // };
        socket.onclose = function(e) {
          try{console.log('echo-protocol Client Closed');}
            catch(e){console.log(e)}  
            console.log(e)
        };
        
        socket.onmessage = function(e) {
          // console.log("message from server",e.data)
          const message = JSON.parse(e.data)
          console.log(message)
            if (typeof e.data === 'string') {
            //    console.log(message)
            //    if (message.type === "sendMessage"){
            //        console.log(message.data.isStreamer)
            //    }
            }
        };

}
export const socketOpen = (isStreamer,username,userId)=>{
    console.log("socket open")
    socket.onopen = () => {
        console.log('WebSocket Client Connected!!!!!!!');
        console.log(socket.readyState)
        const message = {
                type: "joinRoom",
                data: {
                    username: username.current,
                    userId: userId.current,
                    isStreamer: isStreamer,
                    //   roomID: roomId.current,
                    //   id: userId.current
                }
        }
        socket.send(JSON.stringify(message))
        };

        socket.onerror = function() {
            console.log('Connection Error');
        };
        
        
        // socketRef.current.onmessage = (message) => {
        //   console.log(message);
        // };
        socket.onclose = function(e) {
          try{console.log('echo-protocol Client Closed');}
            catch(e){console.log(e)}  
            console.log(e)
        };
        
        socket.onmessage = function(e) {
          // console.log("message from server",e.data)
          const message = JSON.parse(e.data)
          console.log(message)
            if (typeof e.data === 'string') {
                console.log(message)
                if (message.type === "sendMessage"){
                     console.log(message.data.isStreamer)
                }
            }
        };

}


export const messageSocket =(receivedMessage) =>{

    socket.onmessage = function(e) {
        // console.log("message from server",e.data)
        const message = JSON.parse(e.data)
        console.log(message)
          if (typeof e.data === 'string') {
            if (message.type === "receiveMessage"){
                   receivedMessage(message.data)
            }
          }
      };

}

export const messageSocketLobby =() =>{

    socket.onmessage = function(e) {
        // console.log("message from server",e.data)
        const message = JSON.parse(e.data)
        console.log(message)
          if (typeof e.data === 'string') {
            if (message.type === "sendMessage"){
                    console.log(message.data)
            }
          }
      };

}

export const connectSocket =(userId,startTime,chats,setChats,setotherThingmoji,setClusterArray) =>{
    //set up the socket id and load the startstream time if any.
    socket.onmessage = function(e) {
        // console.log("message from server",e.data)
        const message = JSON.parse(e.data)
        console.log(message)
          if (typeof e.data === 'string') {
            if  (message.type === "receiveMessage"){
                console.log("received message")
                // console.log(Date.now() - message.data.seconds)
                receivedMessage(message.data,setChats)
             }else if (message.type === "thingmoji"){
                console.log("received thingmoji")
                otherThingMojiMessage(message.data,setotherThingmoji)
             }else if (message.type === "receiveStartTime"){
                startTime.current = message.data
             }else if (message.type === "receiveCluster"){
                console.log(message.data)
                //push the cluster to the chat images setChats filter the chat with the same message id and push the cluster to the images
                // console.log(message.data.cluster_labels.length)
                const clusterLabel = message.data.cluster_labels
            setClusterArray.current = clusterLabel
             }else if (message.type ==="sendMessage"){
                // if (message.data.id ===userId.current){
                    console.log("starttime",message.data.startTime)
                    startTime.current = message.data.startTime
                    
                    console.log(message.data.chats)
                    if (message.data.chats.length!==0){
                        Array.from(message.data.chats).map((chat)=>{
                            console.log(chat)
                            receivedMessage(chat,setChats)
                        }   
                        )  
                    }
                        if (message.data.thingmojiData.length!==0 ){
                            Array.from(message.data.thingmojiData).map((thingmoji)=>{
                                console.log(thingmoji)
                                otherThingMojiMessage(thingmoji,setotherThingmoji)
                            }   
                            )  
                        }
                // }
            //    }
               console.log(startTime.current)
             }
            }
          }

}


const otherThingMojiMessage = (messageData,setotherThingmoji) => {
    setotherThingmoji(prevState => [...prevState, messageData]);
  }

  function getCurrentTime() {
    let currentDate = new Date();
    let am_pm = currentDate.toLocaleTimeString();
    return am_pm;
  }

const receivedMessage = (messageData,setChats) => {
    // if (messageData.id !== yourID) {
      if (messageData.hasImage) {
          setChats(prev => [...prev, {
            username: messageData.username,
            userId: messageData.userId,
            texts: messageData.texts,
            images: messageData?.images,
            time: getCurrentTime(),
            seconds: messageData.seconds,
            messageId: messageData.messageId,
            index: messageData.index,
            isStreamer: messageData.isStreamer,
            // originImage: messageData?.originImage,
        }]);
      } else {
          setChats(prev => [...prev, {
              username: messageData.username,
              userId: messageData.userId,
              texts: messageData.texts,
              // img: "../assets/image/head.png",
              images: [],
              // originImage: [],
              time: getCurrentTime(),
              seconds: messageData.seconds,
              messageId: messageData.messageId,
              index: messageData.index,
              isStreamer: messageData.isStreamer,
          }]);
      }
      // ReactDOM.findDOMNode(chatsRef.current).scrollTop = ReactDOM.findDOMNode(chatsRef.current).scrollHeight;
          // ReactDOM.findDOMNode(chatsRef.current).scrollIntoView({ behavior: "smooth", block: "end" })
    // }
}



// export const emitAdd = (obj)=>{
//     obj['socketd'] = socket.id
//     socket.emit("object-added",obj)
// }
export const socketID = ()=>{
    console.log(socket.id)
    return socket.id}
export const connectCamera = ()=>{
    socket.on("after connect", data => {
        console.log(data)
    })
    // emit('after connect',  {'data':'Lets dance'})
}


// export const messageSocket =(receivedMessage) =>{
//     socket.on("message", (message) => {
//         // console.log(message)
//         receivedMessage(message);
//         // document.getElementById("chatlists").scrolltobottom = true;

//         // ReactDOM.findDOMNode(document.getElementById("chatlists")).scrollTop = ReactDOM.findDOMNode(document.getElementById("chatlists")).scrollHeight;
//     })
// }


export const messageSend =(messageObject) =>{
    // console.log(messageObject)
    try{
        socket.send(JSON.stringify(messageObject))
    }catch(e){
        console.log(e)
    }
    // socket.emit("send message", messageObject);
    // socket.send(JSON.stringify(messageObject))
}

export const joinRoomSend =(isStreamer,username,userId) =>{
    // console.log(messageObject)
    const message = {
        type: "joinRoom",
        data: {
            username: username.current,
            userId: userId.current,
            isStreamer: isStreamer,
        }
    }
    // socket.emit("send thingmoji", messageObject);
    socket.send(JSON.stringify(message))
}

export const thingmojiSocket =(receivedMessage) =>{
    socket.on("thingmoji", (message) => {
        console.log(message)
        receivedMessage(message);
    })
}

export const thingmojiSend =(messageObject) =>{
    // console.log(messageObject)
    const message = {
        type: "sendThingmoji",
        data: messageObject,
    }
    // socket.emit("send thingmoji", messageObject);
    socket.send(JSON.stringify(message))
}

export const sendStartTime =(messageObject) =>{
    console.log(messageObject)
    const message = {
        type: "startStream",
        data: messageObject,
    }
    socket.send(JSON.stringify(message))
}

// export const sendStartTime =(messageObject) =>{
//     console.log(messageObject)
//     socket.emit("start stream", messageObject);
// }

export const getStartTime =(messageObject) =>{
    console.log(messageObject)
    socket.on("startTime", (message) => {
        console.log(message)
        messageObject.current = message
        // receivedMessage(message);
    })
    // socket.emit("startTime", messageObject);
}