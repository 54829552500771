export const  loadMask = (maskRef,canvasImage)=>{
    var context = canvasImage.current.getContext("2d")
    canvasImage.current.style.width = document.getElementById("video").getBoundingClientRect().width+"px"
    console.log(maskRef.current.getBoundingClientRect().width)
    console.log(document.getElementById("video").getBoundingClientRect().width)
   
    canvasImage.current.style.height = document.getElementById("video").getBoundingClientRect().height +"px"
    // contextRef.current = canvasbody.current.getContext("2d")
    // const bytestring= data.image_data[Object.keys(data.image_data)[index]]
    // console.log(data.contour_dic)
    
    // console.log(contour)
    // maskRef.current.setAttribute('src', 'data:image/jpeg;base64,'+bytestring.split('\'')[1]);
    // maskRef.current.setAttribute('src', bytestring);
   
    maskRef.current.onload = function(){
      drawRemoveBlack(maskRef.current,context,canvasImage.current)
    }
}

export const drawRemoveBlack = (img,ctx,canvas) => {
    var bufferctx =canvas.getContext('2d');
    bufferctx.drawImage(img, 0, 0,canvas.width,  canvas.height);
    var imageData = bufferctx.getImageData(0,0,canvas.width,  canvas.height);
    var data = imageData.data;
    // console.log(data)
    removeBlackOnly(ctx,data,imageData)
}

var removeBlackOnly = function(ctx,data,imageData) {
    for (var i = 0; i < data.length; i += 4) {
         if(data[i]+ data[i + 1] + data[i + 2] <50){ 
              data[i + 3] = 0; // alpha
          }          
          else{
              data[i] = 255
              data[i+1] = 255
              data[i+2] = 224
              data[i + 3] = 180;
            }
      } 
    ctx.putImageData(imageData, 0, 0); 

};


export const highlightHover = (contextObject,canvasObject,userVideo,canvasImage)=>{
    const stopHover = setInterval(() => {
        contextObject.clearRect(0,0,canvasObject.width,canvasObject.height)
        canvasObject.style.width = userVideo.current.getBoundingClientRect().width +"px"
        canvasObject.style.height = userVideo.current.getBoundingClientRect().height+"px"
        contextObject.filter = "blur(1px)"
        if (canvasImage.current!==null){
          // console.log(canvasImage.current)
             contextObject.drawImage(canvasImage.current,0,0,canvasObject.width,canvasObject.height)
        }
    }, 20);
    return stopHover
}

export function videoMouseEnter(stopCaptureObject,contextObject,canvasObject,userVideo,canvasContour){
    if (contextObject.current!==undefined){
        console.log("entervideo")
        stopCaptureObject.current = highlightHover(contextObject.current,canvasObject.current,userVideo,canvasContour)
    }
}

export function videoMouseUp(){
    
    setTimeout(() => {
        if (document.getElementById("contextSnapshots")!==null){
            document.getElementById("contextSnapshots").remove()
        }
    }, 50);
}