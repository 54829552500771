// import * as React from "react";
import Chatbox from "../chat/Chatbox.js";
// import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
// import {getStream} from './chatfunctions'
import React, { useState, useEffect, useRef,useCallback } from "react";
import styled from "styled-components";
import './style.css';
import ButtonUI from "../button/ButtonUI";
import Segmenter from '../Segmenter';
import Button from 'react-bootstrap/Button';
import WhiteBoard from "../imageEditor/WhiteBoard";
// import ButtonUI from "../button/ButtonUI";
// import React, { useEffect, useRef } from 'react';
import { Client, LocalStream } from 'ion-sdk-js';
import { IonSFUJSONRPCSignal } from 'ion-sdk-js/lib/signal/json-rpc-impl';
import { v4 as uuidv4 } from 'uuid';
import {connectSocket,sendStartTime,socketOpen,joinRoomSend } from "./socket";
import Timeline from "./Timeline";

const Page = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  background-color: white;
  flex-direction: column;
`;


const chats_data = [
    {
    username: "g0shie",
    texts: [],
    img: "https://e7.pngegg.com/pngimages/532/985/png-clipart-tom-cat-kitten-tom-and-jerry-talking-tom-and-friends-tom-and-jerry-mammal-heroes.png",
    time: "3h20m20s",
    images: [],
    // picture: "../../assets/manga/items/item_2.png",
    // originImage: "../../assets/manga/3h20m18s.png",
    hidden: "none"
  },
]

// const timeToSeconds = (time) => {
//   const [hours, minutes, seconds] = time.split(/[hms]/).filter(Boolean).map(Number);
//   return (hours * 3600) + (minutes * 60) + seconds;
// };

const timeToPercentage = (time, totalVideoTime) => {
  // const totalVideoTimeInSeconds = totalVideoTimeInHours * 3600;
  console.log(time)
  console.log(totalVideoTime)
  return (time / totalVideoTime) * 100;
};


// const timeToPercentage = (time, totalVideoTimeInHours = 10.5) => {
//   const totalVideoTimeInSeconds = totalVideoTimeInHours * 3600;
//   return (timeToSeconds(time) / totalVideoTimeInSeconds) * 100;
// };


const Viewer = (props) => {
    const [yourID, setYourID] = useState();
    const userStream = useRef()
    const imgSrc = useRef('')
    const canvasImage = useRef()
    const maskRef = useRef()
    const [isDrawing, setIsDrawing] = useState(false);
    const largeWhiteboardRef = useRef()
    // const videoWidth = document.querySelector("#videocontainer").style.width
    // const videoHeight = document.querySelector("#videocontainer").style.height
    const [whiteboard, setWhiteBoard] = useState(false)
    // const yourID = useRef()
    const socketRef= useRef()
    const stopCaptureObject = useRef()
    const videoRef = useRef();
    const canvasRef = useRef();
    const canvasCombined =useRef()
    const dataarray = useRef(["../assets/data/0h2m29s.png",
    "../assets/data/0h2m29s.npy",
   "../assets/data/mobile_sam.onnx",
   0, null
   ])
    const [notes, setNotes] = React.useState([dataarray.current]);
    // const chatsRef = React.useRef(null);
    const [chats, setChats] = React.useState([]);
    const [scrollToTime, setScrollToTime] = useState(-1);
    const [isShow, setIsShow] = React.useState(false);
    const [clicks, setClicks] = useState([]);
    const [selected, setSelected] = useState([]);
    const [embeddingPath, setEmbeddingPath] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const startTime = useRef(null)
    const [otherthingmoji, setotherThingmoji] = React.useState([]);
    // let client, signal;
    const client= useRef()
    const signal = useRef()
    const username = useRef();
    const roomId = useRef();
    const userId = useRef();
    const clusterArray = useRef([]);

    // const socketRef = useRef();

    useEffect(() => {
      const href = location.pathname;
      roomId.current = href.split('/').pop();
      // roomId.current = new URLSearchParams(window.location.search).get("roomname")?.split('?username=')[0];
      username.current = new URLSearchParams(window.location.search).get("username")?.split('?id=')[0];
      // console.log(new URLSearchParams(window.location.search).get("username"))
      userId.current = uuidv4();
      console.log("username: ", username.current, "roomname: ", roomId.current);
  
  
      socketOpen(false,username,userId)
      joinRoomSend(true,username,userId)
      connectSocket(userId,startTime,chats,setChats,setotherThingmoji,clusterArray)
  }, []);


  //   useEffect(() => {
  //     connectSocket(setYourID,startTime,setChats,setotherThingmoji)
  //     // getStartTime(startTime)

  // }, []);


    useEffect(() => {
      // signal.current = new IonSFUJSONRPCSignal("ws://localhost:7000/ws");
      signal.current = new IonSFUJSONRPCSignal("wss://server.openmichci.com/ws")
      client.current = new Client(signal.current, config);
      // signal.current.onopen = () => client.current.join(roomId.current)
      signal.current.onopen = () => client.current.join(roomId.current);
      
      client.current.ontrack = (track, stream) => {
        // console.log("got track: ", track.id, "for stream: ", stream.id);

        track.onunmute = () => {
          videoRef.current.srcObject = stream;
          videoRef.current.autoplay = true;
          videoRef.current.muted = false;
          //play video
          // videoRef.current.play();
          document.getElementById("stream-status").style.display = "none";
          // alert(`The Streamer has started streaming, click "play" if the video was paused!`)
          
          //once video started, set Whiteboard
          
          stream.onremovetrack = () => {
            videoRef.current.srcObject = null;
          }
        }
      }
      // console.log("setwhtieboard")
      
      
      setWhiteBoard(true)

    }, []);



      const config = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
    ],
  };


  const handleThumbnailClick = (messageId) => {
    const videoElement = videoRef.current;
    const dataPoint = chats.find(data => data.messageId === messageId);
    // if (dataPoint && isFinite(videoElement.duration)) {
    //     const newTime = (dataPoint.time / 100) * videoElement.duration;
    //     videoElement.currentTime = newTime;
    //     const progress = (newTime / videoElement.duration) * 100;
    //     setProgressValue(progress); // Update the progressValue state here
    // }
    if(dataPoint){
      console.log(dataPoint.messageId)
      // setProgressValue(dataPoint.seconds)
      setScrollToTime(dataPoint.messageId)
    }
  };


    const CloseButton = (id,index) => {
      document.getElementById("float-box").removeChild(document.getElementById(id))
      deleteNote(index)
      setIsShow(prevState => !prevState);
    }

    const deleteNote = (index) => {
      const newNotes = notes.filter((note) => note[3] !== index-1);
      setNotes(newNotes);
    };
    
    function SegmenterAdd(note,index) {
      // const id = `testsegmenter${index}`
      // if (document.getElementById(id) != null && index!=0){
      //   document.getElementById("float-box").removeChild(document.getElementById(`testsegmenter${index-1}`))
      //   deleteNote(index-1)
      // }
      return (
        <div id="testsegmenter" className="testsegmenter" key={index} style={{position:"absolute",height: "100%", width: "auto",display:"block"}}>
            <div style={{marginLeft:"5px",position:"absolute",zIndex:"250",
            backgroundColor:"rgb(211,211,211,0.5)",borderRadius:"5px",padding:"5px"}}
            >Create a ThingMoji</div>
            {/* <Button size='sm' variant="success" style={{zIndex:"100"}} className="position-absolute top-0 end-0"
            onClick={()=>{CloseButton(id,index)}}> x </Button> */}
            <img id="image-finded" style={{pointerEvents:"none"}}></img>
            <Segmenter IMAGE_PATH={imagePath}
                  IMAGE_EMBEDDING={embeddingPath}
                  MODEL_DIR={note[2]}
                  id = {index}
                  defaultpoint={selected}
             /> 
        </div>
      );
    }


      
    return (
        <Page id="page">
            <p id="header">ThingMoji</p>
            <div id="videocontainer"> 
            <video autoPlay 
            // src="./assets/video.mp4" id="video"
             ref={videoRef} playsInline></video>
           <div id="stream-status" style={{display:"block",color:"red"}}>
            The Streamer has not started streaming!</div>
      <Timeline startTime={startTime}
        chats={chats} setScrollToTime={setScrollToTime} clusterArray={clusterArray} videoRef={videoRef}
        isStreamer={false}/>
            {whiteboard &&
            <WhiteBoard whiteboardRef={largeWhiteboardRef} videoRef={videoRef}
            isDrawing={isDrawing} setIsDrawing={setIsDrawing} width={null} height={null} 
            othersize={document.querySelector("#videocontainer")}
            canvasRef = {canvasRef} 
            canvasCombined= {canvasCombined}
            notes={notes} setNotes={setNotes} 
            clicks={clicks} setClicks={setClicks}
            selected={selected} setSelected={setSelected}
            setEmbeddingPath={setEmbeddingPath}
            setImagePath={setImagePath}
            setWhiteBoard={setWhiteBoard}
            username = {username}
            />
          }
          <canvas id="keyframe" ref={canvasRef}></canvas>
          {/* <div id="float-box" style={{display:"none"}}>
          {notes.map(SegmenterAdd)}
          
            <canvas style={{visibility:"hidden",display:"absolute",pointerEvents:"none"}} ref={canvasCombined}></canvas>
          </div> */}
          {/* <div id="float-thingmoji"></div> */}
            </div>
            <div>
            <ButtonUI whiteboard={whiteboard}
            // userStream={userStream} setWhiteBoard={setWhiteBoard} 
            // canvasImage={canvasImage} maskRef={maskRef} client={client} startTime={startTime}
            // start={null} isStreamer={false} 
            setWhiteBoard={setWhiteBoard}           
            LocalStream = {LocalStream} start={null}
            videoRef ={videoRef} client={client} startTime={startTime}
            values={null} isStreamer={false}/>
                </div>
            <div id='chatboxcontainer'>
            {/* <Chatbox yourID={yourID} chats={chats} setChats={setChats}
            scrollToTime={scrollToTime}
            setotherThingmoji={setotherThingmoji} otherthingmoji={otherthingmoji}
            socketRef={socketRef}
            username={username} userId={userId}
            isStreamer={false}
            />     */}
            <Chatbox yourID={yourID} 
            username={username} userId={userId}
            chats={chats} setChats={setChats} scrollToTime={scrollToTime}
            setotherThingmoji={setotherThingmoji} otherthingmoji={otherthingmoji}
            socketRef={socketRef} 
            isStreamer = {false}
            notes={notes}
            canvasCombined={canvasCombined}
            // selected={selected}
            selected={selected} setSelected={setSelected}
            embeddingPath={embeddingPath}
            imagePath={imagePath}
            />    
            {/* <Chatbox yourID={yourID} notes={notes} setNotes={setNotes} chatsRef={chatsRef}/>  */}
            </div>
        </Page>
    )
}

export default Viewer;