// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Afr5uaarboCVjDUjNdcL {
  margin: 2px;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  overflow: "hidden";
  font-family: "Pacifico", cursive;
  transition-duration: 0.15s;
}

.Afr5uaarboCVjDUjNdcL:hover {
 background-color: #E0E0E0;
}`, "",{"version":3,"sources":["webpack://./src/routes/CreateRoom.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gCAAgC;EAChC,0BAA0B;AAC5B;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".button {\r\n  margin: 2px;\r\n  width: 150px;\r\n  height: 30px;\r\n  border-radius: 5px;\r\n  overflow: \"hidden\";\r\n  font-family: \"Pacifico\", cursive;\r\n  transition-duration: 0.15s;\r\n}\r\n\r\n.button:hover {\r\n background-color: #E0E0E0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Afr5uaarboCVjDUjNdcL`
};
export default ___CSS_LOADER_EXPORT___;
