import React, {useState} from 'react'
import styled from "styled-components";
import axios from 'axios';
import {start,stop} from './client';
import { loadMask,drawRemoveBlack } from '../utils/maskfunc';
import {sendStartTime} from '../routes/socket';
// import { Tooltip } from '@material-ui/core';
import { ReactDOM } from 'react';
import SwitchMode from './SwitchMode';

// prior background-color: #282c34;
const Button = styled.button`
    background-color: #212529;
    color: white;
    font-size: 15px;
    padding: 12px;
    border-radius: 5px;
    margin: 1px;
    cursor: pointer;
    &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
    }
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border:0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
`;

const switchMode = {
  position: "absolute",
  left: "305px",
  top: "352px",
  width: "100px",
  display: "flex",
  height: "30px",
  margin: "auto",
  color: "white",
}

const groupHolder = {
  position: "absolute",
  left: "395px",
  top: "354px",
  width: "100px",
  display: "flex",
  height: "30px",
  margin: "auto",
  color: "white",
}

const ButtonToggle = styled(Button)`
opacity: 0.6;
${({ active }) =>
active &&
`
opacity: 1;
`}
`;
const ButtonGroup = styled.div`
display: flex-wrap;
`;

export default function ButtonShare({whiteboard,setWhiteBoard,start,LocalStream,videoRef,client,startTime,values,isStreamer}) {
    
    const [active,setActive] = useState()

    //button types
    // const types = ['Show Object', "Focus Share"];
    
    const [startStream, setStartStream] = useState("Start Stream")
    // const [annotation, setAnnotation] = useState("Start Annotation")
    
    const [mute, SetMute] = useState("Mute")
    // const [process,setProcess] = useState("Process Video")
    // const [text,setText] = useState("Get KeyText")
    // const [FocucsText, setFocusText] = useState("Focus Share")
    const types = [startStream, mute];
    // const typesViewer = [annotation]
    const [videoSource,setVideoSource] = useState(null)
    const videoStream = React.useRef(null)
    const [publishButton,setPublishButton] = useState(false)
    const [audioSource,setAudioSource] = useState(null)
    const [audiovalues,setaudioValues] = useState([{id:"",label:"Select audio source"}])
    
  


    
    const ButtonFunction = (type)=>{
      
        if (type === "Start Stream" ||type === "Stop Stream"){
            if (type === "Start Stream"){
                setStartStream("Stop Stream")
                startTime.current = Date.now()
                sendStartTime(startTime.current)
                // start(true)
                // var videoSelect = document.getElementById("videoSource")
                // console.log(videoSelect.value)
              // const videoSource = videoSelect.value;
            if (videoStream.current!==null){
              client.current.publish(videoStream.current);
              const videoTrack = videoStream.current.getVideoTracks()[0]
              videoTrack.enabled = true
            }
            }else{
                setStartStream("Start Stream")
                //remove video stream
                const videoTrack = videoStream.current.getVideoTracks()[0]
                // videoTrack.stop()
                videoTrack.enabled = false
                console.log(videoTrack)
                // client.current.unpublish()
            }
        } 
        else if(type === "Mute" ||type ==="Unmute" ){
            
            if(type === "Mute"){
                SetMute("Unmute")
                // videoRef.current.muted = true
                const autioTrack = videoStream.current.getAudioTracks()[0]
                autioTrack.enabled = false
            }else{
                SetMute("Mute")
                const autioTrack = videoStream.current.getAudioTracks()[0]
                autioTrack.enabled = true
            }
        }
    }

    function getSelectedText() {
        var selectedText = '';
      
        // window.getSelection
        if (window.getSelection) {
            selectedText = window.getSelection();
        }
        // document.getSelection
        else if (document.getSelection) {
            selectedText = document.getSelection();
        }
        // document.selection
        else if (document.selection) {
            selectedText =
            document.selection.createRange().text;
        } else return;
        // To write the selected text into the textarea
        // document.testform.selectedtext.value = selectedText;
        console.log(selectedText)
    }

  const changeStream = (event) => {
    if (videoStream.current!==null){
        const videoTrack = videoStream.current.getVideoTracks()[0]
        // videoTrack.stop()
        videoTrack.enabled = false
    }
    event.preventDefault();
    var videoSelect = document.getElementById("videoSource")
    console.log(videoSelect.value)
    const selectedSource = videoSelect.value;
    //change the selected value to the selected text
    videoSelect.selected = selectedSource
    console.log(videoSelect.selected)
    setVideoSource(selectedSource)
    // console.log()

    // if (event) {
    LocalStream.getUserMedia({
      resolution: 'vga',
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      codec: "vp8",
      video: {deviceId: selectedSource ? {exact: selectedSource} : undefined}
      // deviceId: event.target.value
    }).then((media) => {
    videoRef.current.srcObject = media;
    videoRef.current.autoplay = true;
    videoRef.current.controls = true;
    videoRef.current.muted = true;
    videoStream.current = media

    })
    .then(()=>{
       //change the stop stream button to start stream
        setStartStream("Start Stream")
        setWhiteBoard(true)
    })
    .catch(console.error).then(getDevices)
    // .then(gotDevices);
    // }
    
    
    
}


const changeAudio = (event) => {
  // if (videoStream.current!==null){
  //     const videoTrack = videoStream.current.getVideoTracks()[0]
  //     // videoTrack.stop()
  //     videoTrack.enabled = false
  // }
  event.preventDefault();
  var audioSelect = document.getElementById("audioSource")
  // console.log(videoSelect.value)
  const selectedSource = audioSelect.value;
  //change the selected value to the selected text
  audioSelect.selected = selectedSource
  // console.log(videoSelect.selected)
  setAudioSource(selectedSource)
  
  // console.log()

  // if (event) {
  //change audio source
  LocalStream.getUserMedia({
    resolution: 'vga',
    audio: {deviceId: selectedSource ? {exact: selectedSource} : undefined},
    codec: "vp8",
    video: {deviceId: videoSource ? {exact: videoSource} : undefined}
    // deviceId: event.target.value
  }).then((media) => {
  videoRef.current.srcObject = media;
  videoRef.current.autoplay = true;
  videoRef.current.controls = true;
  videoRef.current.muted = true;
  videoStream.current = media

  }
  )
  .then(()=>{
     //change the stop stream button to start stream
      setStartStream("Start Stream")
      setWhiteBoard(true)
  })
  .catch(console.error).then(getDevices)
  
  
}


  function gotDevices(deviceInfos) {
    window.deviceInfos = deviceInfos; // make available to console
    var videoSelect = document.getElementById("videoSource")
    // var audioSelect = document.getElementById("audioSource")
    console.log('Available input and output devices:', deviceInfos);
    Array.from(videoSelect.children).map((child) => child.remove());
    // videoSelect.removeChild(videoSelect.children.map((child) => child.value !== "" && child)));
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      // if (deviceInfo.kind === 'audioinput') {
      //   option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
      //   audioSelect.appendChild(option);
      // } else 
      if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        videoSelect.appendChild(option);
      }
    }
  }

  function getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  function loadCamera(){
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      for(var i = 0; i < devices.length; i ++){
          var device = devices[i];
          if (device.kind === 'videoinput') {

            // values.push([device.deviceId,device.label || 'camera ' + (i + 1)])
              // var option = document.createElement('option');
              // option.value = device.deviceId;
              // console.log(device.deviceId)
              // option.text = device.label || 'camera ' + (i + 1);
              // document.querySelector('select#videoSource').appendChild(option);
          }
  };
});
  }

 
 return ( 
  <div id = "buttongroup">
     <ButtonGroup>
        {/* {isStreamer &&  */}

      {isStreamer &&  <select id="videoSource" onChange={changeStream} value="">

            {values && values.map((value,index) => {
              // console.log(value.label)
              
              return (
              
              <option key={index} value={value.id}>
                {value.label}
              </option>
            )}
            )}
                {/* <option>
                Select camera source
                </option> */}
            </select>
      }

      {isStreamer &&  <select id="audioSource" onChange={changeAudio} value="">
           {audiovalues && audiovalues.map((value,index) => {
              // console.log(value.label)
              
              return (
              
              <option key={index} value={value.id}>
                {value.label}
              </option>
            )}
            )}

      {/* {values && values.map((value,index) => {
        // console.log(value.label)
        
        return (
        
        <option key={index} value={value.id}>
          {value.label}
        </option>
      )}
      )} */}
          {/* <option>
          Select camera source
          </option> */}
      </select>
      }
      {/* {!publishButton? */}
      {/* <SwitchMode id="swtich" style={{display:"none"}} setWhiteBoard={setPublishButton} SwitchLabel={"Publish"}  */}
      {/* firstState={false}/> */}
      {isStreamer ? types.map((type,index) => (
            // <div>
            <ButtonToggle
            key={index}
            active={active === type}
            onClick={() => {
              setActive(type)
              ButtonFunction(type)
            }} >
            {type}
            </ButtonToggle>
          ))
          : null
          }
          
              {/* <select id="audioSource"</select> */}
              <SwitchMode style={{display:"inline-block"}} setWhiteBoard={setWhiteBoard} SwitchLabel={"Annotation"}
              firstState={true}/>
        </ButtonGroup>
          </div>
   )
}