import React, {useEffect, useState, useRef} from "react";
import {resizeImage_original} from "../components/helpers/imageUtils";
import axios from 'axios';
import { take } from "underscore";
// import { saveDrawing } from "../util/saveDrawing";
//init a basic whiteboard function that supports drawing and clear
export default function WhiteBoard({whiteboardRef,isDrawing,setIsDrawing, width, height, 
  othersize,videoRef,canvasRef, canvasCombined,setNotes,notes, selected, setSelected, clicks, setClicks
,setEmbeddingPath,setImagePath,setWhiteBoard, username}) {
  const [image, setImage] = useState(null);
  const dataset = useRef([]);
//   const contextRef = useRef(null);
  
//   const width = tabpages.current.getBoundingClientRect().width
//   const height = tabpages.current.getBoundingClientRect().height
  const stopinterval = useRef()
  const contextRef = useRef(null)
  

  // const canvasRef = useRef(null)
  // const canvasCombined = useRef(null)

  if (width===null){
    width = videoRef.current.getBoundingClientRect().width
    height = videoRef.current.getBoundingClientRect().height
  }
  

  useEffect(() => {
    const RandomImage = new Image();
    RandomImage.crossOrigin = 'anonymous';
    RandomImage.src = "https://picsum.photos/1024/768"
    RandomImage.onload = () => setImage(RandomImage)
  }, []);

  useEffect(() => {
    // if(image && canvasRef) {

     contextRef.current = whiteboardRef.current.getContext("2d")
      // setStream(canvasRef.current.captureStream(30));
     contextRef.current.fillStyle = "rgba(255, 255, 255, 0)"
     contextRef.globalAlpha = 0.2;
     contextRef.current.fillRect(0, 0, width, height)
    // contextRef.current.drawImage(image, 0, 0)

    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [image, canvasRef]);
  },[])

  const addText = (text, fill, stroke) => {
      contextRef.current.clearRect(0, 0, whiteboardRef.current.width, whiteboardRef.current.height);
      contextRef.current.fillStyle = "rgba(255,255,255, 0)"
      contextRef.current.fillRect(0, 0, width, height)
     // contextRef.current.drawImage(image, 0, 0)
      contextRef.current.font = "123px verdana";
      contextRef.current.fillStyle = fill;
      contextRef.current.strokeStyle = stroke;
      contextRef.current.lineWidth = 1;
      // contextRef.current.numSmoothPoints = 10;
      contextRef.current.fillText(text, 10, 120);
      contextRef.current.strokeText(text, 10, 120);
      clearInterval(stopinterval.current)
    //   socketRef.current.emit("whiteboard-canvas-data",{id:socketRef.current.id,clear:true})
  }


  async function fetchAndCombineChunks(url,dataURL,notes,signal) {

    // const response = await fetch(url);

    const startTime = performance.now(); // Start timing
      const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: `{
            "Id": 78912,
            "dataURL": "${dataURL}",
            "id": "${notes.length-1}",
            "Price": 18.00
            }`,
          signal: signal
          });



    
    const reader = response.body.getReader();
     
    
    let receivedLength = 0;
    let chunks = [];  // Array to hold received chunks
    const firstDataTime = performance.now(); // End timing when the response is received
    console.log(`Time to start receiving data: ${(firstDataTime - startTime)/1000} seconds`)

    while (true) {
        const {done, value} = await reader.read();

        if (done) {
            break;
        }

        chunks.push(value);
        receivedLength += value.length;
    }

    const endTime = performance.now(); // Stop timing after all chunks are received

    console.log(`Total time: ${(endTime - startTime)/1000} seconds`);
    // Combine chunks into a single Uint8Array
    let combined = new Uint8Array(receivedLength);
    let position = 0;
    for(let chunk of chunks) {
        combined.set(chunk, position);
        position += chunk.length;
    }

    setWhiteBoard(true)

    // Process the combined array
    // const floats = new Float32Array(combined.buffer)
    // setEmbeddingPath(floats)
    // ...
    return combined;
}


  async function getStream(dataURL,notes,setNotes) {
      setEmbeddingPath(null)
      setImagePath(dataURL)

      // const url = 'http://localhost:8000/tests'
      const url = 'https://embedding.openmic.com.ngrok.app/tests'
        const data = {
          dataURL:dataURL,
          // clicks:clicks,
          id: notes.length-1,
        };
        const start = Date.now();
        axios.post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          })
          .then(({data}) => {
          const end = Date.now();
          console.log("time",end-start)
            // console.log(data);
            const datanp = data.image_embedding
            const bytes = Uint8Array.from(datanp, c => c.charCodeAt(0))
            const floats = new Float32Array(bytes.buffer)
            setEmbeddingPath(floats)
        });

      // // const response = await fetch("https://embedding.openmic.com.ngrok.app/tests", {
      //   const response = await fetch("http://localhost:8000/tests", {
      //     method: 'POST',
      //     headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json',
      //     },
      //     body: `{
      //       "Id": 78912,
      //       "dataURL": "${dataURL}",
      //       "id": "${notes.length-1}",
      //       "Price": 18.00
      //       }`,
      //     });
    
      //   const image = new Image();
      //   image.src = "./assets/loading.gif"
      //   image.id = "loading"
      //   image.style.width = "80px"
      //   document.getElementById("float-thingmoji").appendChild(image)
      //   const start = Date.now();
        
      //   response.json().then(data => {
      //     console.log("username",username.current)
      //     const end = Date.now();
      //     console.log("time",end-start)
      //     document.getElementById("float-thingmoji").removeChild(image)
      //     const datanp = data.image_embedding
      //     const bytes = Uint8Array.from(datanp, c => c.charCodeAt(0))
      //     const floats = new Float32Array(bytes.buffer)
      //     setEmbeddingPath(floats)
      //   });
  }



  function saveNote(setNotes,notes,newNote) {
    // counter++;
    // Append the new note to the old array.
    //load the segmenter with the default points saved from the front end. 
    setNotes((notes)=> [...notes, newNote]);
    console.log(notes);
  }

  const startDrawing = ({nativeEvent}) => {
    console.log("startDrawing")
    //restart by cleaning the canvas
    contextRef.current.clearRect(0, 0, whiteboardRef.current.width, whiteboardRef.current.height);
    const { offsetX, offsetY } = nativeEvent;
    console.log(offsetX, offsetY)

    // dataset.current[0] = {x:offsetX, y:offsetY,clickType:1};
    contextRef.current.beginPath();
    contextRef.current.strokeStyle = '#0172bd33';
    contextRef.globalAlpha = 0.2;
    contextRef.current.lineWidth = 10;
    contextRef.current.numSmoothPoints = 30;

    // contextRef.current.putImageData(imageImageData, 0, 0);

    contextRef.current.moveTo(offsetX, offsetY);

    setIsDrawing(true);
    var context = canvasRef.current.getContext('2d');
    contextRef.current.fillStyle = 'green';
    contextRef.current.fillRect(offsetX, offsetY, 10, 10);

    const videoPosition = videoRef.current.getBoundingClientRect()
    const width = videoPosition.width;
    const height = videoPosition.height;
    
    //downgrade image size
      // canvasRef.current.width = 500;
      // canvasRef.current.height = 500/width * height;

      // context.drawImage(videoRef.current, 0, 0, 500, 500/width * height);


      const overlayImage = document.createElement("img")

      // var data = canvasRef.current.toDataURL('image/png');

      canvasRef.current.width = width;
      canvasRef.current.height = height;
      context.drawImage(videoRef.current, 0, 0, width, height);
      const overlayImageData = canvasRef.current.toDataURL('image/png');
      overlayImage.setAttribute('src', overlayImageData);
      overlayImage.style.position = "absolute";
      overlayImage.classList = "viz-image"
      document.getElementById("videocontainer").append(overlayImage)
    
    
}



function takepicture(videoRef) {
  
  // var context = canvasRef.current.getContext('2d');
  // // if (width && height) {
  //   // console.log(videoRef.current.width)
  //   const videoPosition = videoRef.current.getBoundingClientRect()
  //   const width = videoPosition.width;
  //   const height = videoPosition.height;
    
  //   //downgrade image size
  //     // canvasRef.current.width = 500;
  //     // canvasRef.current.height = 500/width * height;

  //     // context.drawImage(videoRef.current, 0, 0, 500, 500/width * height);


      const overlayImage = document.createElement("img")

  //     // var data = canvasRef.current.toDataURL('image/png');

  //     canvasRef.current.width = width;
  //     canvasRef.current.height = height;
  //     context.drawImage(videoRef.current, 0, 0, width, height);
      const overlayImageData = canvasRef.current.toDataURL('image/png');
      overlayImage.setAttribute('src', overlayImageData);
      overlayImage.style.position = "absolute";
      // const imageData = resizeImage_original(overlayImage,500)
      //get the image saved 
      // dataset.current["image"] = data;
      document.getElementById("float-loading").style.display = "block"
      setEmbeddingPath(null)
      setImagePath(overlayImageData)

      try{
      // getStream(overlayImageData,notes,setNotes)

      const url = 'https://embedding.openmic.com.ngrok.app/tests'
      // const url = 'http://localhost:8000/tests'
      // fetchAndCombineChunks('https://embedding.openmic.com.ngrok.app/tests',overlayImageData,notes).then(combined => {
        const controller = new AbortController();
        const signal = controller.signal;
      const abortfunction = setTimeout(() => 
        {
          try{
           controller.abort()
           setWhiteBoard(true)
            document.getElementById("float-loading").style.display = "none"

           alert("Sorry! Thingmoji was lost on the way. Please try again.")
          } catch(err){
            console.log(err)
          }
          
        }, 10000);

 
      fetchAndCombineChunks(url,overlayImageData,notes,signal).then(combined => {
        
          // The combined Uint8Array with your embedding data
          // console.log(combined);
         const floats = new Float32Array(combined.buffer)
         setEmbeddingPath(floats)

         clearTimeout(abortfunction)
          // Convert 'combined' to the appropriate format and process it
      });
      }catch(err){  
        console.log(err)
      }
    


    
      
      //send the point to the server upon finish the pointing. 
}

function savetotextfield(){
  var ctx3 = canvasCombined.current.getContext('2d');
  const videoPosition = videoRef.current.getBoundingClientRect()
  const width = videoPosition.width;
  const height = videoPosition.height;
  canvasCombined.current.width = width;
  canvasCombined.current.height = height;
  // ctx3.drawImage(canvasRef.current, 0, 0);
  ctx3.drawImage(whiteboardRef.current, 0, 0);
  const dataURL = canvasCombined.current.toDataURL();
  // console.log(dataURL)
  // const image = new Image()
  // const messageImage = document.createElement("image")
  
  const image = document.getElementById("image-finded")
  // const dataURL = canvasCombined.current.toDataURL();
  image.src = dataURL
  image.style.width = document.querySelector("#float-box").getBoundingClientRect().width+"px"
  image.width = document.querySelector("#float-box").getBoundingClientRect().width
  // image.id = "message-selected"
  // image.width = document.querySelector("#form").style.width
  // document.querySelector("#float-box").appendChild(image)
  if (document.getElementsByClassName("viz-image")!==null){
    // console.log(Array.from(document.getElementsByClassName("viz-image")))
    Array.from(document.getElementsByClassName("viz-image")).forEach(element => {
      document.getElementById("videocontainer").removeChild(element)
    });
      
  }
  contextRef.current.clearRect(0, 0, whiteboardRef.current.width, whiteboardRef.current.height);
}


const finishDrawing = ({nativeEvent}) => {
  takepicture(videoRef);
  const { offsetX, offsetY } = nativeEvent;
 
  // dataset.current[1] = {x:offsetX, y:offsetY,clickType:1}
  
  setSelected(clicks ? [clicks[0], clicks[Math.floor(clicks.length / 2)], clicks[clicks.length - 1]] : []);
  console.log(selected)
  setClicks([]);
  contextRef.current.fillStyle = 'green';
  contextRef.current.fillRect(offsetX, offsetY, 10, 10);
  console.log(offsetX, offsetY)
  contextRef.current.closePath();
  setIsDrawing(false);
  clearInterval(stopinterval.current)
  savetotextfield()
  // takepicture(videoRef);
  setWhiteBoard(false)
  // document.getElementById("myWhiteboard").style.pointerEvents = "none"
  //set canvas to be unclickable
  //send the point to the server upon finish the pointing. 
}

//find the mask using the two points.
// const callformask = async() =>{
//     start = Date.now();
//     try {
//         results = await decodingSession.run(decodingFeeds);
//         console.log("Generated mask:", results);
//         const mask = results.masks;
//         const maskImageData = mask.toImageData();
//         context.globalAlpha = 0.5;
//         // convert image data to image bitmap
//         let imageBitmap = await createImageBitmap(maskImageData);
//         context.drawImage(imageBitmap, 0, 0);

//     } catch (error) {
//         console.log(`caught error: ${error}`)
//     }
//     end = Date.now();
// }

const draw = ({nativeEvent}) => {
      if (!isDrawing) {
        clearInterval(stopinterval.current)
        return
      }else{
      const { offsetX, offsetY } = nativeEvent;
      contextRef.current.lineTo(offsetX, offsetY);
      contextRef.current.stroke();
      // const scale = videoRef.current.getBoundingClientRect().width/500
      const click = {x:offsetX, y:offsetY,clickType:1}
      if (click) setClicks(clicks?[...clicks,click]:[click]);
    }
}

  return (
    <>
      <canvas 
        id="myWhiteboard"
        ref={whiteboardRef}
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        width={width}
        height={height}

      />
      {/* <IconContext.Provider value={{ color:"orange", position:"absolute", cursor: 'pointer', size: '40px', id: "global-class-name" }}>
        <div className='rightcursor' id="annotation">
      <AiOutlineClear id="clearbutton" onClick={() => { 
        
        addText("", "", "");
        
         }}>clear</AiOutlineClear>
        </div>
    </IconContext.Provider> */}
    </>
  )
}