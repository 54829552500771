import React, { useState, useEffect, useRef } from "react";
import '../routes/style.css'
import styled from "styled-components";
import ButtonShare from './ButtonShare';

export default function ButtonUI({whiteboard,setWhiteBoard,LocalStream,start,videoRef,client,startTime,values,setValues,isStreamer}){

  return (
    <div id ='my-button'>
       <ButtonShare whiteboard={whiteboard}
       setWhiteBoard={setWhiteBoard} 
        LocalStream = {LocalStream}
       start={start} videoRef={videoRef} 
       client={client} startTime={startTime} values={values}
       setValues={setValues}
       isStreamer={isStreamer}/>
    </div> 
  )
};