export function resizeImage(image: HTMLImageElement, size: number) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = size;
    canvas.height = size;
    if (image.height > image.width) {
        const width = size / image.height * image.width;
        ctx?.drawImage(image,size/2-width/2,0,width,size);
    } else {
        const height = size / image.width * image.height;
        ctx?.drawImage(image,0,size/2-height/2,size,height);
    }
    var imageResized = new Image();
    imageResized.src = canvas.toDataURL();
    return imageResized;
}

export function resizeImage_original(image: HTMLImageElement, size: number) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = size;
    canvas.height = size / image.width * image.height;
    ctx?.drawImage(image,0,0,canvas.width,canvas.height);
    var imageResized = new Image();
    imageResized.src = canvas.toDataURL();
    return imageResized;
}


export function removeBlankSpace(imageData: ImageData, imgWidth: number, imgHeight: number) {
    // var imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height),
    var data = imageData.data,
           getRBG = function(x: number, y: number) {
                      return {
                        red:   data[(imgWidth*y + x) * 4],
                        green: data[(imgWidth*y + x) * 4 + 1],
                        blue:  data[(imgWidth*y + x) * 4 + 2],
                        alpha: data[(imgWidth*y + x) * 4 + 3]
                      };
                    },
           isWhite = function (rgb: any) {
                      return rgb.red == 255 && rgb.green == 255 && rgb.blue == 255;
                    },
           scanY = function (fromTop: any) {
                      var offset = fromTop ? 1 : -1;
  
                      // loop through each row
                      for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {
  
                        // loop through each column
                        for(var x = 0; x < imgWidth; x++) {
                            if (!isWhite(getRBG(x, y))) {
                                return y;                        
                            }else{
                                imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                            }
                            
                        }
                    }
                    return null; // all image is white
                },
            scanYWhite = function (fromTop: any) {
                    var offset = fromTop ? 1 : -1;

                    // loop through each row
                    for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                      // loop through each column
                      for(var x = 0; x < imgWidth; x++) {
                          if (isWhite(getRBG(x, y))) {
                              imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                          }
                      }
                  }
                  return null; // all image is white
              },
            scanX = function (fromLeft: any) {
                      var offset = fromLeft? 1 : -1;
  
                      // loop through each column
                      for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {
  
                        // loop through each row
                        for(var y = 0; y < imgHeight; y++) {
                            if (!isWhite(getRBG(x, y))) {
                                return x;                        
                            }  else{
                                imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                            }
                        }
                    }
                    return null; // all image is white
                },            
            scanXWhite = function (fromLeft: any) {
                    var offset = fromLeft? 1 : -1;

                    // loop through each column
                    for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                      // loop through each row
                      for(var y = 0; y < imgHeight; y++) {
                          if (isWhite(getRBG(x, y))) {
                              imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                          }
                      }
                  }
                  return null; // all image is white
              };;
  
        var cropTop = scanY(true),
            cropBottom = scanY(false),
            cropLeft = scanX(true),
            cropRight = scanX(false);
        scanYWhite(true);
        scanYWhite(false);
        scanXWhite(true);
        scanXWhite(false);
        var cropWidth = (cropRight!==null&&cropLeft!==null) ? cropRight - cropLeft : 0,
            cropHeight = (cropBottom!==null&&cropTop!==null) ? cropBottom - cropTop : 0;
    
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        // canvas.setAttribute("width", cropWidth.toString());
        // canvas.setAttribute("height", cropHeight.toString());
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        ctx?.putImageData(imageData, cropLeft?-cropLeft:0, cropTop?-cropTop:0, cropLeft?cropLeft:0, cropTop?cropTop:0, cropWidth, cropHeight);
        // finally crop the guy
        // const imageObject = imageDataToImage(imageData);
        // ctx?.drawImage(imageObject,
            // cropLeft?cropLeft:0, cropTop?cropTop:0, cropWidth, cropHeight,
            // 0, 0, cropWidth, cropHeight);
    
        var image = new Image();
        image.width = cropWidth;
        image.height = cropHeight;
        image.src = canvas.toDataURL();
        // console.log(image);
        return image
        // return canvas.toDataURL();
    // cropTop is the last topmost white row. Above this row all is white
    // cropBottom is the last bottommost white row. Below this row all is white
    // cropLeft is the last leftmost white column.
    // cropRight is the last rightmost white column.
  };

export function DimBlankSpace(imageData: ImageData, imgWidth: number, imgHeight: number,originalImage: HTMLImageElement, imageData_original: Uint8Array) {
    // var imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height),
    var data = imageData.data,
           getRBG = function(x: number, y: number) {
                      return {
                        red:   data[(imgWidth*y + x) * 4],
                        green: data[(imgWidth*y + x) * 4 + 1],
                        blue:  data[(imgWidth*y + x) * 4 + 2],
                        alpha: data[(imgWidth*y + x) * 4 + 3]
                      };
                    },
           isWhite = function (rgb: any) {
                      return rgb.red == 255 && rgb.green == 255 && rgb.blue == 255;
                    },
           scanY = function (fromTop: any) {
                      var offset = fromTop ? 1 : -1;
  
                      // loop through each row
                      for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {
  
                        // loop through each column
                        for(var x = 0; x < imgWidth; x++) {
                            if (!isWhite(getRBG(x, y))) {
                                return y;                        
                            }else{
                                imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                            }
                            
                        }
                    }
                    return null; // all image is white
                },
            scanYWhite = function (fromTop: any) {
                    var offset = fromTop ? 1 : -1;

                    // loop through each row
                    for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                      // loop through each column
                      for(var x = 0; x < imgWidth; x++) {
                          if (isWhite(getRBG(x, y))) {
                              imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                          }
                      }
                  }
                  return null; // all image is white
              },
            scanX = function (fromLeft: any) {
                      var offset = fromLeft? 1 : -1; 
                        // loop through each column
                        for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {
                            // loop through each row
                            for(var y = 0; y < imgHeight; y++) {
                                if (!isWhite(getRBG(x, y))) {
                                    return x;                        
                                }  else{
                                    imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                                }
                            }
                        }
                    return null; // all image is white
                }
                ,
            scanXWhite = function (fromLeft: any) {
                    var offset = fromLeft? 1 : -1;

                    // loop through each column
                    for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                      // loop through each row
                      for(var y = 0; y < imgHeight; y++) {
                          if (isWhite(getRBG(x, y))) {
                              imageData.data[(imgWidth*y + x) * 4 + 3] = 0;
                          }
                      }
                  }
                  return null; // all image is white
                };; 
        var cropTop = scanY(true),
            cropBottom = scanY(false),
            cropLeft = scanX(true),
            cropRight = scanX(false);
        scanYWhite(true);
        scanYWhite(false);
        scanXWhite(true);
        scanXWhite(false);
        var cropWidth = (cropRight!==null&&cropLeft!==null) ? cropRight - cropLeft : 0,
            cropHeight = (cropBottom!==null&&cropTop!==null) ? cropBottom - cropTop : 0;
     
        const canvas = document.createElement("canvas");
        const canvas_image = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const ctx_image = canvas_image.getContext("2d");
        // canvas.setAttribute("width", cropWidth.toString());
        // canvas.setAttribute("height", cropHeight.toString());
        canvas.width = originalImage.width;
        canvas.height = originalImage.height;
        //dim the original image to semitransparent and possibily be undefined
        // ctx?.globalAlpha = 0.5;
        if (ctx) {
            ctx.globalAlpha = 0.5;
        }
        // ctx?.globalAlpha = 0.5 : 1;
        // const segmentation = new Uint8ClampedArray(imageData.map((pixel, index) => pixel === 0 ? imageData[index]*0.5: imageData[index]));
        ctx?.drawImage(originalImage,0,0);
        var image1 = ctx?.getImageData(0, 0, originalImage.width, originalImage.height);
        var imageData1 = image1?.data;
        console.log(imageData1?.buffer)
        // 
        var pixels = 4 * originalImage.width * originalImage.height;
        for (var i = 0; i < pixels; i += 4) {
            if (imageData.data[i + 3] === 0) {
                imageData1![i + 3] = 100;
            }else{
                imageData1![i + 3] = 255;
            
            }
        }
        // if (ctx_image) {
        //     ctx_image.putImageData(image1!, 0, 0);
        // }
        ctx?.putImageData(image1!, 0,0);
        ctx_image?.putImageData(imageData!, 0,0);
      
        // canvas_image.width = originalImage.width;
        // canvas_image.height = originalImage.height;

        // ctx?.putImageData(imageData, cropLeft?-cropLeft:0, cropTop?-cropTop:0, cropLeft?cropLeft:0, cropTop?cropTop:0, cropWidth, cropHeight);
        
        // finally crop the guy
        // const imageObject = imageDataToImage(imageData);
        
        // ctx?.drawImage(imageObject,
            // cropLeft?cropLeft:0, cropTop?cropTop:0, cropWidth, cropHeight,
            // 0, 0, cropWidth, cropHeight);

        var image = new Image();
        image.width = originalImage.width;
        image.height = originalImage.height;
        image.src = canvas.toDataURL();

        // var image_2 = new Image();
        // image_2.width = originalImage.width;
        // image_2.height = originalImage.height;
        // image_2.src = canvas_image.toDataURL();
        // console.log(image);
        return image
        // return canvas.toDataURL();
    
    // cropTop is the last topmost white row. Above this row all is white
    // cropBottom is the last bottommost white row. Below this row all is white
    // cropLeft is the last leftmost white column.
    // cropRight is the last rightmost white column.
    }