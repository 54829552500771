import React from 'react';
import CreateRoomStyle from "./CreateRoom.module.css";

const LoginForm = ({setUsername, setRoomname, RoomName, checkStreamerPw,checkViewerPw}) => {
  function showPopup() {
    document.getElementById("popup").style.display = "block";
}

function done() { 
  document.getElementById("popup").style.display = "none";
  var password = document.getElementById("pass").value;
  // LoginStreamer()
  //DO STUFF WITH PASSWORD HERE
};
    return (
      <div className="w-full max-w-xs">
    <form className="bg-white dark:bg-gray-700 shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
    style={{padding:"10px",borderRadius:"2%"}}>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-100 text-sm font-bold mb-2" htmlFor="username">
          User Name
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="User name"
              onChange={(event) => setUsername(event.currentTarget.value)}
              autoComplete="off"
        />
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-gray-100 text-sm font-bold mb-2" htmlFor="password">
          Room Name
        </label>
        <input className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="roomname"
              type="text"
              placeholder="Streaming room name"
              //value={RoomName ? RoomName : ""}
              onChange={(event) => setRoomname(event.currentTarget.value)}
              autoComplete="off"
        />
       {/*<p className="text-red-500 text-xs italic">Please choose a password.</p> */}
      </div>
      <div id="popup">
          <div>Enter Password:</div>
          <input className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password" type="password"/>
      </div>
      <div className="flex items-center justify-between">
        <button onClick={checkStreamerPw} className={CreateRoomStyle.button}>
          Create Stream
        </button>
        <button onClick={checkViewerPw} className={CreateRoomStyle.button}>
          Join Stream
        </button>
      </div>
    </form>
    {/* <p className="text-center text-gray-500 text-xs">
      &copy;2021 HCI Lab. Prototyping OpenMic 
    </p> */}
  </div>
    )
  }
  
  export default LoginForm;