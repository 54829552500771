async function getStream() {
  const response = await fetch("http://localhost:8000/tests", {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  
  },
  body: `{
     "Id": 78912,
     "Customer": "Jason Sweet",
     "Quantity": 1,
     "Price": 18.00
    }`,
  });
  
  response.json().then(data => {
    console.log(JSON.stringify(data));
  });
}


// function capture() {
//     var canvas = document.getElementById('canvas'); 
//     canvas.style.display = "block"
//     var video = document.querySelector('video')
//     canvas.width = video.videoWidth;
//     canvas.height = video.videoHeight;
//     canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  
//     /** Code to merge image **/
//     /** For instance, if I want to merge a play image on center of existing image **/
//     const playImage = new Image();
//     playImage.src = 'path to image asset';
//     playImage.onload = () => {
//       const startX = (video.videoWidth / 2) - (playImage.width / 2);
//       const startY = (video.videoHeight / 2) - (playImage.height / 2);
//       canvas.getContext('2d').drawImage(playImage, startX, startY, playImage.width, playImage.height);
//       // canvas.toBlob() = (blob) => {
//       //   const img = new Image();
//       //   img.src = window.URL.createObjectUrl(blob);
//       // };
  
     
//     };
//     // var file = document.querySelector('canvas').toDataURL();
//     // var reader = new FileReader()
//     // reader.readAsDataURL(file);
//     return document.querySelector('canvas').toDataURL();
//     // console.log(imgSrc.current)
//     /** End **/
// }